// GENERATED FILE - DO NOT MODIFY
import { CreatePatientRequestDto } from 'Api/Features/Patients/Dtos/CreatePatientRequestDto';
import { ExportPatientStatisticsReportRequestDto } from 'Api/Features/Patients/Dtos/ExportPatientStatisticsReportRequestDto';
import { GetPatientPreferencesResponseDto } from 'Api/Features/Patients/Dtos/GetPatientPreferencesResponseDto';
import { GetPhysicanPatientsRequestDto } from 'Api/Features/Patients/Dtos/GetPhysicanPatientsRequestDto';
import { GetPhysicanPatientsResponseDto } from 'Api/Features/Patients/Dtos/GetPhysicanPatientsResponseDto';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import { SetPatientPreferencesRequestDto } from 'Api/Features/Patients/Dtos/SetPatientPreferencesRequestDto';
import { UpdatePatientRequestDto } from 'Api/Features/Patients/Dtos/UpdatePatientRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PatientProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPatient(patientId: string): Promise<PatientDto | null> {
        const uri = this.buildUri(
            "/patients/{patientId}",
            { patientId: patientId },
            null
        );

        const data: PatientDto | null = await this.httpClient.get<PatientDto | null>(uri);
        return data;
    }

    public async getPhysicanPatients(physicianId: string, request: GetPhysicanPatientsRequestDto | null): Promise<GetPhysicanPatientsResponseDto | null> {
        const uri = this.buildUri(
            "/physicians/{physicianId}/patients",
            { physicianId: physicianId },
            { ...request || undefined }
        );

        const data: GetPhysicanPatientsResponseDto | null = await this.httpClient.get<GetPhysicanPatientsResponseDto | null>(uri);
        return data;
    }

    public async createPatient(request: CreatePatientRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/patients",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreatePatientRequestDto | null, string>(uri, request);
        return data;
    }

    public async updatePatient(patientId: string, request: UpdatePatientRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/patients/{patientId}",
            { patientId: patientId },
            null
        );

        await this.httpClient.patch<UpdatePatientRequestDto | null, void>(uri, request);
    }

    public async deletePatient(patientId: string): Promise<void> {
        const uri = this.buildUri(
            "/patients/{patientId}",
            { patientId: patientId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getPatientPreferences(patientId: string): Promise<GetPatientPreferencesResponseDto | null> {
        const uri = this.buildUri(
            "/patients/{patientId}/preferences",
            { patientId: patientId },
            null
        );

        const data: GetPatientPreferencesResponseDto | null = await this.httpClient.get<GetPatientPreferencesResponseDto | null>(uri);
        return data;
    }

    public async setPatientPreferences(patientId: string, request: SetPatientPreferencesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/patients/{patientId}/preferences",
            { patientId: patientId },
            null
        );

        await this.httpClient.put<SetPatientPreferencesRequestDto | null, void>(uri, request);
    }

    public async exportPatientStatisticsReport(patientId: string, request: ExportPatientStatisticsReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/patients/{patientId}/patient-statistics-report/export",
            { patientId: patientId },
            null
        );

        const data: ArrayBuffer = await this.httpClient.postAsBuffer<ExportPatientStatisticsReportRequestDto | null>(uri, request);
        return data;
    }

    public async exportPatientData(patientId: string): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/patients/{patientId}/export-data",
            { patientId: patientId },
            null
        );

        const data: ArrayBuffer = await this.httpClient.postAsBuffer<void>(uri, );
        return data;
    }
}