import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ChevronUp: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 24 14" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.31776 12.9663C0.627152 12.2757 0.627152 11.156 1.31776 10.4654L10.7493 1.03379C11.081 0.702149 11.5308 0.515833 11.9998 0.515833C12.4688 0.515833 12.9186 0.702149 13.2503 1.03379L22.6818 10.4654C23.3725 11.156 23.3725 12.2757 22.6818 12.9663C21.9912 13.6569 20.8715 13.6569 20.1809 12.9663L11.9998 4.78518L3.81869 12.9663C3.12808 13.6569 2.00837 13.6569 1.31776 12.9663Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ChevronUp;
