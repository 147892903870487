import React, { FunctionComponent, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormValidation, useService, useStores } from 'Hooks';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Input, Spin } from 'antd';
import { ResetPasswordEmailSchema } from 'Schemas/ResetPasswordSchema';
import './forgot-password.less';
import { ChevronLeft, Mail } from 'Components/icons';
import { theme } from 'Style/theme';
import { UserService } from 'Services/UserService';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';
import { useTranslation } from 'react-i18next';

const ForgotPassword: FunctionComponent = () => {
    const { t } = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [errors, validateForm] = useFormValidation(ResetPasswordEmailSchema);
    const userService = useService(UserService);
    const [redirectPath, setRedirect] = useState<string>();
    const [spinner, setSpinner] = useState(false);
    const history = useHistory();
    const { toastStore } = useStores();

    if (redirectPath) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: { resetPasswordEmail: true },
                }}
            />
        );
    }
    return (
        <div className="ForgotPassword">
            <Row>
                <Col>
                    <div className="Paragraph-bold back" onClick={(): void => history.goBack()}>
                        <ChevronLeft fill={theme['primary-color']} />
                        <span className="text">{t('ForgotPassword.back_to_login')}</span>
                    </div>
                </Col>
            </Row>
            <Row className="heading">
                <Col className="H2-bold">{t('ForgotPassword.reset_password')}</Col>
            </Row>

            <Row className="heading">
                <Col className="Paragraph">{t('ForgotPassword.enter_email_address')}</Col>
            </Row>
            <Form
                layout="vertical"
                onFinish={async (values): Promise<void> => {
                    const model: ForgotPasswordRequestDto = {
                        email: values.email,
                    };
                    const valid = await validateForm(model);
                    if (!valid) return;
                    try {
                        setSpinner(true);
                        await userService.forgotPassword(model);
                    } finally {
                        setSpinner(false);
                        toastStore.toast({
                            type: 'success',
                            message: t(`ForgotPassword.reset_password_sent`),
                        });
                        setRedirect('/');
                    }
                }}
            >
                <Row gutter={[0, 50]}>
                    <Col span={16} offset={4}>
                        <ValidatedFormItem
                            errors={errors}
                            label={t('email')}
                            name="email"
                            className="email-input"
                        >
                            <Input prefix={<Mail fill={theme['primary-color']} />} maxLength={50} />
                        </ValidatedFormItem>

                        {spinner && (
                            <div className="spinner">
                                <Spin indicator={antIcon} size="large" />
                            </div>
                        )}
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit" className="send-btn">
                    {t('send')}
                </Button>
            </Form>
        </div>
    );
};

export default ForgotPassword;
