// GENERATED FILE - DO NOT MODIFY
import { GetSurveysRequestDto } from 'Api/Features/Surveys/Dtos/GetSurveysRequestDto';
import { GetSurveysResponseDto } from 'Api/Features/Surveys/Dtos/GetSurveysResponseDto';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SurveyProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSurveys(request: GetSurveysRequestDto | null): Promise<GetSurveysResponseDto | null> {
        const uri = this.buildUri(
            "/surveys",
            null,
            { ...request || undefined }
        );

        const data: GetSurveysResponseDto | null = await this.httpClient.get<GetSurveysResponseDto | null>(uri);
        return data;
    }

    public async getSurvey(surveyId: string): Promise<SurveyDto | null> {
        const uri = this.buildUri(
            "/surveys/{surveyId}",
            { surveyId: surveyId },
            null
        );

        const data: SurveyDto | null = await this.httpClient.get<SurveyDto | null>(uri);
        return data;
    }
}