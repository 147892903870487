import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/CreatePhysicianRequestDto';
import { PhysicianDto } from 'Api/Features/Physicians/Dtos/PhysicianDto';
import { UpdatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/UpdatePhysicianRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import ImagePicker from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditDoctorSchema } from 'Schemas/EditDoctorSchema';
import { PhysicianService } from 'Services/PhysicianService';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditDoctorModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: PhysicianDto;
}

const EditDoctorModal: FunctionComponent<EditDoctorModalProps> = ({
    visible,
    onComplete,
    user,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [errors, validateForm, resetErrors] = useFormValidation(EditDoctorSchema);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const physicianService = useService(PhysicianService);
    const [imageUrl, setImageUrl] = useState<string | undefined>(user?.imageUrl ?? undefined);
    const [base64, setBase64] = useState<string>();

    //#endregion

    //#region Effects
    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
            });
        }
    }, [user, form]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: t('ConfirmModal.confirm_modal_title'),
                message: t('ConfirmModal.confirm_modal_message'),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const data = form.getFieldsValue();

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();
            if (user === undefined) {
                const request: CreatePhysicianRequestDto = {
                    ...data,
                    image: base64
                        ? ({
                              uploadBase64: base64,
                          } as UpdateFileRequestDto)
                        : null,
                };
                await physicianService.createPhysician(request);
            } else {
                const request: UpdatePhysicianRequestDto = {
                    ...data,
                    image: base64
                        ? ({
                              uploadBase64: base64,
                          } as UpdateFileRequestDto)
                        : null,
                };
                await physicianService.updatePhysician(user.id!, request);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Doctor ${user !== undefined ? 'updated' : 'created'} successfully`,
            });
            dismiss(true);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t(user !== undefined ? 'edit_entity' : 'create_entity', {
                param1: t('doctor'),
            })}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditDoctorModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <ImagePicker
                        onFileChange={(imageUrl, base64) => {
                            setImageUrl(imageUrl);
                            setBase64(base64);
                        }}
                        imageUrl={imageUrl}
                    />

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('first_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('last_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="email" label={t('email')}>
                                <Input disabled={user !== undefined} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="phoneNumber"
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save_changes')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditDoctorModal);
