import { ExportSurveyResponseReportRequestDto } from 'Api/Features/SurveyResponses/Dtos/ExportSurveyResponseReportRequestDto';
import { GetSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesRequestDto';
import { GetSurveyResponsesResponseDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesResponseDto';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { SurveyResponseProxy } from 'Api/Features/SurveyResponses/SurveyResponseProxy';
import { inject } from 'aurelia-dependency-injection';
import { API_DATE_TIME_REQUEST_FORMAT } from 'Models/Constants';
import moment from 'moment';
import { arrayBufferToXlsx } from 'Utils/Blob';
import { ApiService } from './ApiService';

@inject(SurveyResponseProxy)
export class SurveyResponseService extends ApiService {
    constructor(private readonly surveyResponseProxy: SurveyResponseProxy) {
        super();
    }

    public async getSurveyResponse(surveyResponseId: string): Promise<SurveyResponseDto | null> {
        const data: SurveyResponseDto | null = await this.surveyResponseProxy.getSurveyResponse(surveyResponseId);
        return data;
    }

    public async getSurveyResponses(request: GetSurveyResponsesRequestDto | null): Promise<[SurveyResponseDto[], number]> {
        const data: GetSurveyResponsesResponseDto | null = await this.surveyResponseProxy.getSurveyResponses(request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async exportSurveyResponse(request: ExportSurveyResponseReportRequestDto | null): Promise<Blob> {
        if(request?.startDate) {
            request.startDate = moment(request.startDate).format(API_DATE_TIME_REQUEST_FORMAT)
        }
        if(request?.endDate) {
            request.endDate = moment(request.endDate).format(API_DATE_TIME_REQUEST_FORMAT)
        }
        const data = await this.surveyResponseProxy.exportSurveyResponseReport(request);
        return arrayBufferToXlsx(data);
    }
}
