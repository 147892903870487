import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CircleX: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 36 36" fill="none" {...props}>
                <path
                    d="M19.753 17.9989L25.0989 12.6647C25.333 12.4305 25.4646 12.113 25.4646 11.7818C25.4646 11.4507 25.333 11.1331 25.0989 10.899C24.8648 10.6649 24.5473 10.5333 24.2162 10.5333C23.8851 10.5333 23.5676 10.6649 23.3335 10.899L18 16.2457L12.6665 10.899C12.4324 10.6649 12.1149 10.5333 11.7838 10.5333C11.4527 10.5333 11.1352 10.6649 10.9011 10.899C10.667 11.1331 10.5355 11.4507 10.5355 11.7818C10.5355 12.113 10.667 12.4305 10.9011 12.6647L16.247 17.9989L10.9011 23.3332C10.7846 23.4488 10.6921 23.5863 10.629 23.7378C10.5658 23.8894 10.5333 24.0519 10.5333 24.216C10.5333 24.3802 10.5658 24.5427 10.629 24.6942C10.6921 24.8457 10.7846 24.9833 10.9011 25.0989C11.0167 25.2154 11.1542 25.3079 11.3057 25.371C11.4572 25.4342 11.6197 25.4667 11.7838 25.4667C11.9479 25.4667 12.1104 25.4342 12.2619 25.371C12.4134 25.3079 12.5509 25.2154 12.6665 25.0989L18 19.7522L23.3335 25.0989C23.4491 25.2154 23.5866 25.3079 23.7381 25.371C23.8896 25.4342 24.0521 25.4667 24.2162 25.4667C24.3803 25.4667 24.5428 25.4342 24.6943 25.371C24.8458 25.3079 24.9833 25.2154 25.0989 25.0989C25.2154 24.9833 25.3079 24.8457 25.3711 24.6942C25.4342 24.5427 25.4667 24.3802 25.4667 24.216C25.4667 24.0519 25.4342 23.8894 25.3711 23.7378C25.3079 23.5863 25.2154 23.4488 25.0989 23.3332L19.753 17.9989Z"
                    fill={fill}
                ></path>
                <rect x="1" y="1" width="34" height="34" rx="17" stroke={fill} strokeWidth="2" />
            </svg>
        </span>
    );
};

export default CircleX;
