import React, { FunctionComponent } from "react";
import "./tag.less";

export interface TagProps {
  text: string;
  color: TagColor;
  size?: TagSize;
  hasBullet?: boolean;
}

export enum TagColor {
    Purple = 'Purple',
    Grey = 'Grey',
    Green = 'Green'
}

export enum TagSize {
  Small = 'Small',
  Big = 'Big'
}

const Tag: FunctionComponent<TagProps> = ({ text, color, size = TagSize.Big, hasBullet }) => {
    return (
        <div className={`Tag ${color} ${size}`}>
            <div className="inner-container">
                {hasBullet && <div className="circle" />}
                <div className="text">{text}</div>
            </div>
        </div>
    );
};

export default Tag;
