import React, { FunctionComponent } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';

interface ValidatedFormItemProps extends FormItemProps {
    errors?: Map<string, string[]>;
}

const ValidatedFormItem: FunctionComponent<ValidatedFormItemProps> = ({
    children,
    name,
    label,
    errors,
    ...props
}) => {
    const fieldErrors = errors?.get(name?.toString() || '');
    const errorProps: FormItemProps = {};
    if (fieldErrors) {
        errorProps.hasFeedback = true;
        errorProps.help = fieldErrors.map((errorKey) => `${errorKey}`);
        errorProps.validateStatus = 'error';
    }
    
    return (
        <Form.Item {...props} name={name} label={label} {...errorProps}>
            {children}
        </Form.Item>
    );
};

export default ValidatedFormItem;
