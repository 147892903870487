import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Close: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M14.6293 11.9984L22.6482 3.99699C22.9993 3.64578 23.1966 3.16944 23.1966 2.67275C23.1966 2.17606 22.9993 1.69972 22.6482 1.34851C22.297 0.997296 21.8207 0.799988 21.3241 0.799988C20.8275 0.799988 20.3512 0.997296 20.0001 1.34851L11.9998 9.36856L3.99954 1.34851C3.64838 0.997296 3.1721 0.799988 2.67548 0.799988C2.17887 0.799988 1.70259 0.997296 1.35143 1.34851C1.00027 1.69972 0.802987 2.17606 0.802987 2.67275C0.802987 3.16944 1.00027 3.64578 1.35143 3.99699L9.37035 11.9984L1.35143 19.9998C1.17664 20.1732 1.0379 20.3795 0.943226 20.6068C0.848549 20.834 0.799805 21.0778 0.799805 21.324C0.799805 21.5703 0.848549 21.814 0.943226 22.0413C1.0379 22.2686 1.17664 22.4749 1.35143 22.6483C1.52479 22.8231 1.73105 22.9619 1.9583 23.0565C2.18555 23.1512 2.4293 23.2 2.67548 23.2C2.92167 23.2 3.16541 23.1512 3.39267 23.0565C3.61992 22.9619 3.82617 22.8231 3.99954 22.6483L11.9998 14.6282L20.0001 22.6483C20.1734 22.8231 20.3797 22.9619 20.6069 23.0565C20.8342 23.1512 21.0779 23.2 21.3241 23.2C21.5703 23.2 21.8141 23.1512 22.0413 23.0565C22.2686 22.9619 22.4748 22.8231 22.6482 22.6483C22.823 22.4749 22.9617 22.2686 23.0564 22.0413C23.1511 21.814 23.1998 21.5703 23.1998 21.324C23.1998 21.0778 23.1511 20.834 23.0564 20.6068C22.9617 20.3795 22.823 20.1732 22.6482 19.9998L14.6293 11.9984Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Close;
