import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import AppstoreEN from 'Assets/Images/app-store-en.svg';
import AppstoreFR from 'Assets/Images/app-store-fr.svg';
import AppstoreDE from 'Assets/Images/app-store-de.svg';
import AppstoreES from 'Assets/Images/app-store-es.svg';
import AppstoreIT from 'Assets/Images/app-store-it.svg';
import GooglePlayEN from 'Assets/Images/google-play-en.svg';
import GooglePlayFR from 'Assets/Images/google-play-fr.svg';
import GooglePlayDE from 'Assets/Images/google-play-de.svg';
import GooglePlayES from 'Assets/Images/google-play-es.svg';
import GooglePlayIT from 'Assets/Images/google-play-it.svg';
import './password-confirmed.less';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from 'Models/Constants';
import { useTranslation } from 'react-i18next';

const PasswordConfirmed: FunctionComponent = observer(() => {
    const { t, i18n } = useTranslation();

    const getAppStoreImg = (): string => {
        switch (i18n.language) {
            case 'en':
                return AppstoreEN;
            case 'fr':
                return AppstoreFR;
            case 'de':
                return AppstoreDE;
            case 'es':
                return AppstoreES;
            case 'it':
                return AppstoreIT;
            default:
                return AppstoreEN;
        }
    };

    const getGooglePlayImg = (): string => {
        switch (i18n.language) {
            case 'en':
                return GooglePlayEN;
            case 'fr':
                return GooglePlayFR;
            case 'de':
                return GooglePlayDE;
            case 'es':
                return GooglePlayES;
            case 'it':
                return GooglePlayIT;
            default:
                return GooglePlayEN;
        }
    };

    return (
        <div className="PasswordConfirmed">
            <Row className="heading">
                <Col className="H2-bold">{t('ConsolePasswordConfirmed.its_updated')}</Col>
            </Row>

            <Row className="heading">
                <Col className="Paragraph">{t('ConsolePasswordConfirmed.password_updated')}</Col>
            </Row>

            <Row>
                <Col className="appstores">
                    <img
                        src={getAppStoreImg()}
                        alt={'app store'}
                        onClick={() => window.open(APP_STORE_LINK, '_blank', 'noopener,noreferrer')}
                        className="app-store"
                    />

                    <img
                        src={getGooglePlayImg()}
                        alt={'app store'}
                        onClick={() =>
                            window.open(GOOGLE_PLAY_LINK, '_blank', 'noopener,noreferrer')
                        }
                    />
                </Col>
            </Row>
        </div>
    );
});

export default PasswordConfirmed;
