import * as yup from 'yup';

export const ExportSurveysSchema = yup.object({
    surveyIds: yup.string().required('Required'),
    allTime: yup.bool(),
    startDate: yup.date().nullable().when('allTime', {
        is: false,
        then: yup.date().required('Required'),
    }),
    endDate: yup.date().nullable().when('allTime', {
        is: false,
        then: yup.date().required('Required'),
    }),
});