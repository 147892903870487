import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

interface DonutChartProps {
    data?: DonutChartData[];
    animate?: boolean;
    lineWidth?: number;
}

interface DonutChartData {
    value: number;
    color: string;
}

const DonutChart: FunctionComponent<DonutChartProps> = observer(
    ({ data, animate, lineWidth = 30 }) => {
        return <PieChart data={data} lineWidth={lineWidth} startAngle={270} animate={animate} />;
    }
);

export default React.memo(DonutChart);
