import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { UserProxy } from 'Api/Features/Users/UserProxy';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { ManagerUserDetailsDto } from 'Api/Features/Users/Dtos/ManagerUserDetailsDto';
import { AdminUserDetailsDto } from 'Api/Features/Users/Dtos/AdminUserDetailsDto';
import { GetAdminUsersRequestDto } from 'Api/Features/Users/Dtos/GetAdminUsersRequestDto';
import { GetAdminUsersResponseDto } from 'Api/Features/Users/Dtos/GetAdminUsersResponseDto';
import { UpdateAdminUserRequestDto } from 'Api/Features/Users/Dtos/UpdateAdminUserRequestDto';
import { CreateAdminUserRequestDto } from 'Api/Features/Users/Dtos/CreateAdminUserRequestDto';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import { ChangeEmailConfirmationAnonymousRequestDto } from 'Api/Features/Users/Dtos/ChangeEmailConfirmationAnonymousRequestDto';

@inject(UserProxy)
export class UserService extends ApiService {
    constructor(
        private readonly userProxy: UserProxy
    ) {
        super();
    }

    public async forgotPassword(request: ForgotPasswordRequestDto | null): Promise<void> {
        await this.userProxy.forgotPassword(request);
    }

    public async updateUserPassword(userId: string, request: UpdateUserPasswordAnonymousRequestDto | null): Promise<void> {
        await this.userProxy.updateUserPassword(userId, request);
    }

    public async updateUserPasswordAnonymous(request: UpdateUserPasswordAnonymousRequestDto | null): Promise<void> {
        await this.userProxy.updateUserPasswordAnonymous(request);
    }

    public async getManagerUser(userId: string): Promise<ManagerUserDetailsDto | null> {
        const data: ManagerUserDetailsDto | null = await this.userProxy.getManagerUser(userId);
        return data;
    }

    public async getAdminUser(userId: string): Promise<AdminUserDetailsDto | null> {
        const data: AdminUserDetailsDto | null = await this.userProxy.getAdminUser(userId);
        return data;
    }

    public async getAdminUsers(request: GetAdminUsersRequestDto | null): Promise<[AdminUserDetailsDto[], number]> {
        const data: GetAdminUsersResponseDto | null = await this.userProxy.getAdminUsers(request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createAdminUser(request: CreateAdminUserRequestDto | null): Promise<string> {
        const data: string = await this.userProxy.createAdminUser(request);
        return data;
    }

    public async updateAdminUser(userId: string, request: UpdateAdminUserRequestDto | null): Promise<void> {
        await this.userProxy.updateAdminUser(userId, request);
    }

    public async deleteAdminUser(userId: string): Promise<void> {
        await this.userProxy.deleteAdminUser(userId);
    }

    public async resendAccountConfirmation(request: ResendAccountConfirmationRequestDto | null): Promise<void> {
        await this.userProxy.resendAccountConfirmation(request);
    }

    public async confirmChangeEmail(request: ChangeEmailConfirmationAnonymousRequestDto | null): Promise<void> {
        await this.userProxy.confirmChangeEmail(request);
    }
}
