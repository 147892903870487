import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const ChevronDown: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 16 10" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1214 1.02252C15.5818 1.48293 15.5818 2.2294 15.1214 2.6898L8.83365 8.97752C8.61255 9.19862 8.31268 9.32283 8.00001 9.32283C7.68733 9.32283 7.38746 9.19862 7.16637 8.97752L0.878649 2.6898C0.41824 2.2294 0.418241 1.48293 0.878649 1.02252C1.33906 0.562113 2.08552 0.562113 2.54593 1.02252L8.00001 6.4766L13.4541 1.02252C13.9145 0.562114 14.661 0.562114 15.1214 1.02252Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default ChevronDown;
