import React, { FunctionComponent } from 'react';
import { Col, Divider, Row } from 'antd';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import './profile-information-card.less';
import Tag, { TagColor, TagProps } from 'Components/tag/tag';
import { useTranslation } from 'react-i18next';

export interface ProfileInformationCardProps {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    isDoctorProfile?: boolean;
    tagprops?: TagProps;
    imageUrl?: string | null;
    pendingInvitation?: boolean;
}

const ProfileInformationCard: FunctionComponent<ProfileInformationCardProps> = ({
    firstName,
    lastName,
    email,
    isDoctorProfile,
    tagprops,
    phoneNumber,
    imageUrl,
    pendingInvitation,
}) => {
    const { t } = useTranslation();
    return (
        <div className="ProfileInformationCard">
            <div className="card">
                <div
                    className="header-image-container"
                    style={{ backgroundImage: `url(${imageUrl ?? NoProfilePicture})` }}
                />

                {(tagprops || pendingInvitation) && (
                    <div className="tag-container">
                        {tagprops && (
                            <div className="tag">
                                <Tag text={tagprops.text} color={tagprops.color} />
                            </div>
                        )}

                        {pendingInvitation && (
                            <div className="tag">
                                <Tag text={t('pending_invitation')} color={TagColor.Grey} />
                            </div>
                        )}
                    </div>
                )}

                <Row>
                    <Col span={24} className="H4-regular title text-align-center">
                        {t('personal_information')}
                    </Col>
                </Row>

                <Row>
                    <Col span={11}>
                        <div className="info-container">
                            <span className="Paragraph-small label">{t('first_name')}:</span>
                            <span className="Paragraph-bold value">{firstName}</span>
                        </div>

                        <Divider />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <div className="info-container">
                            <span className="Paragraph-small label">{t('last_name')}:</span>
                            <span className="Paragraph-bold value">{lastName}</span>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Row>
                    <Col span={isDoctorProfile ? 11 : 24}>
                        <div className="info-container">
                            <span className="Paragraph-small label">{t('email')}:</span>
                            <span className="Paragraph-bold value">{email}</span>
                        </div>
                        <Divider />
                    </Col>
                    {isDoctorProfile && <Col span={2}></Col>}
                    {isDoctorProfile && (
                        <Col span={11}>
                            <div className="info-container">
                                <span className="Paragraph-small label">{t('phone_number')}:</span>
                                <span className="Paragraph-bold value">{phoneNumber}</span>
                            </div>
                            <Divider />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default ProfileInformationCard;
