import React, { FunctionComponent, useCallback } from 'react';
import { Button, Layout } from 'antd';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { useStores } from 'Hooks';
import './index.less';
import { useState } from 'react';
import EditProfile from './edit-profile';
import ProfileInformationCard from 'Components/profile-information-card/profile-information-card';
import { TagColor } from 'Components/tag/tag';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const { Content } = Layout;

const Profile: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { globalLoadingStore, userStore } = useStores();
    const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);

    const userRole = useCallback((): ManagementRoleDto | null | undefined => {
        return userStore.userInfo?.managementRoles?.[0]?.name;
    }, []);

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            await userStore.setUserInfo();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore]);

    return (
        <div className="Profile">
            <ListSectionHeader
                action={
                    <Button type="primary" onClick={() => setEditProfileModalOpen(true)}>
                        {t('edit_entity', { param1: t('profile') })}
                    </Button>
                }
            />
            <Content>
                <ProfileInformationCard
                    firstName={userStore.userInfo?.firstName}
                    lastName={userStore.userInfo?.lastName}
                    email={userStore.userInfo?.email}
                    phoneNumber={userStore.userInfo?.phoneNumber}
                    isDoctorProfile={userRole() === ManagementRoleDto.Physician}
                    tagprops={
                        userRole() === ManagementRoleDto.Physician
                            ? {
                                  text: t('ManagementRoleDto.ManagementRoleDto_Physician'),
                                  color: TagColor.Purple,
                              }
                            : userRole() === ManagementRoleDto.Administrator
                            ? {
                                  text: t('ManagementRoleDto.ManagementRoleDto_Administrator'),
                                  color: TagColor.Purple,
                              }
                            : userRole() === ManagementRoleDto.SuperAdministrator
                            ? {
                                  text: t('ManagementRoleDto.ManagementRoleDto_SuperAdministrator'),
                                  color: TagColor.Green,
                              }
                            : undefined
                    }
                    imageUrl={userStore.userInfo?.imageUrl ?? undefined}
                />
            </Content>

            {editProfileModalOpen && userStore.userInfo && (
                <EditProfile
                    visible={editProfileModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetch();
                        setEditProfileModalOpen(false);
                    }}
                    user={userStore.userInfo}
                    isDoctor={userRole() === ManagementRoleDto.Physician}
                />
            )}
        </div>
    );
});

export default Profile;
