import React, { FunctionComponent, useEffect } from 'react';
import MPNLogo from 'Assets/Images/logo-white.svg';
import './anonymous-layout.less';
import { theme } from 'Style/theme';
import { MOMENT_YEAR_FORMAT, SUPPORTED_LANGS } from 'Models/Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const AnonymousLayout: FunctionComponent = ({ children }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (navigator.languages !== undefined) {
            i18n.changeLanguage(navigator.languages[0]);
            if (SUPPORTED_LANGS.some((lang) => lang === navigator.languages[0])) {
                i18n.changeLanguage(navigator.languages[0]);
            } else {
                i18n.changeLanguage('en');
            }
        }
    }, []);

    return (
        <div className="AnonymousLayout">
            <div>
                <img id="logo" src={MPNLogo} alt="logo" />
            </div>
            <div className="square">{children}</div>
            <div className="curved-line">
                <svg viewBox="0 0 500 150">
                    <path
                        d="M-24.41,107.06 C243.80,121.88 245.26,123.84 505.46,105.09 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: theme['primary-color'] }}
                    ></path>
                </svg>
            </div>
            <div className="copyright">{`© ${moment().format(MOMENT_YEAR_FORMAT)} MPNGENIE Inc. ${t(
                'all_rights_reserved'
            )}`}</div>
        </div>
    );
};
export default AnonymousLayout;
