import React from 'react';
import * as yup from 'yup';

export const useFormValidation = (
    schema: yup.ObjectSchema
): [
    Map<string, string[]>,
    (values: object) => Promise<boolean>,
    () => void,
    (errors: Map<string, string[]>) => void
] => {
    const [errors, setErrors] = React.useState(new Map<string, string[]>());

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const validateForm = async (values: object): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false });
            resetErrors();
            return true;
        } catch (validationErrors) {
            const errors = new Map<string, string[]>();
            for (const error of validationErrors.inner) {
                errors.set(error.path, (errors.get(error.path) ?? []).concat(error.errors));
            }
            setErrors(errors);
            return false;
        }
    };

    return [errors, validateForm, resetErrors, setErrors];
};
