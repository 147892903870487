import React, { FunctionComponent, useCallback, useEffect } from 'react';
import AuthenticatedLayout from 'Components/authenticated-layout/authenticated-layout';
import ValidatedRoute from 'Components/validated-route/validated-route';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Patients from './patients';
import Doctors from './doctors';
import Profile from './profile';
import Administrators from './administrators';
import AdministratorProfile from './administrators/id/adminstrator-profile';
import PatientDetails from './patients/id';
import Mpn10 from './patients/id/mpn10';
import DoctorProfile from './doctors/id/doctor-profile';
import NotFoundPage from 'Routes/error/not-found';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import OtherQuestionnaires from './patients/id/other-questionnaires';

interface AuthenticatedProps {
    returnUrl: string;
}

const AuthenticatedRoutes: FunctionComponent<AuthenticatedProps> = observer(({ returnUrl }) => {
    const { userStore, authorizationStore, surveyStore } = useStores();
    const location = useLocation();

    let redirectionUrl = returnUrl;
    if (returnUrl === '') {
        if (userStore.userRole) {
            if (userStore.userRole === ManagementRoleDto.Physician) {
                redirectionUrl = '/patients';
                // route /doctors should not be accessible to doctors, but api does not 403 on call because it is a public call..
                if(location.pathname === '/doctors')
                    authorizationStore.setCurrentRequestForbidden();
            } else if (
                userStore.userRole === ManagementRoleDto.Administrator ||
                userStore.userRole === ManagementRoleDto.SuperAdministrator
            ) {
                redirectionUrl = '/doctors';
            }
        }
    }

    const fetchUserInfo = useCallback(() => {
        userStore.setUserInfo();
    }, []);

    useEffect(() => {
        if (userStore.userInfo === null && !userStore.callInProgress) fetchUserInfo();
    }, [fetchUserInfo, userStore.userInfo]);

    useEffect(() => {
        // Reset forbidden and notFound state on route change.
        authorizationStore.resetForbidden();
        authorizationStore.resetNotFound();
    }, [location, authorizationStore]);

    useEffect(() => {
        surveyStore.setSurveys();
    }, [])

    return (
        <AuthenticatedLayout>
            <Switch>
                <Redirect exact from="/" to={redirectionUrl} />

                <ValidatedRoute exact path="/patients">
                    <Patients />
                </ValidatedRoute>
                <ValidatedRoute path="/patients/:id">
                    <Switch>
                        <Route
                            exact
                            path="/patients/:id"
                            render={({ match }) => (
                                <Redirect to={`/patients/${match.params.id}/mpn10`} />
                            )}
                        />
                        <ValidatedRoute exact path="/patients/:id/mpn10">
                            <PatientDetails>
                                <Mpn10 />
                            </PatientDetails>
                        </ValidatedRoute>

                        <ValidatedRoute exact path="/patients/:id/other-questionnaires">
                            <PatientDetails>
                                <OtherQuestionnaires />
                            </PatientDetails>
                        </ValidatedRoute>

                        <ValidatedRoute>
                            {/* must be last route of patients/id*/}
                            <NotFoundPage />
                        </ValidatedRoute>
                    </Switch>
                </ValidatedRoute>

                <ValidatedRoute exact path="/profile">
                    <Profile />
                </ValidatedRoute>

                <ValidatedRoute exact path="/doctors">
                    <Doctors />
                </ValidatedRoute>
                <ValidatedRoute exact path="/doctors/:id">
                    <DoctorProfile />
                </ValidatedRoute>

                <ValidatedRoute exact path="/administrators">
                    <Administrators />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administrators/:id">
                    <AdministratorProfile />
                </ValidatedRoute>

                <ValidatedRoute>
                    {/* must be last route*/}
                    <NotFoundPage />
                </ValidatedRoute>
            </Switch>
        </AuthenticatedLayout>
    );
});

export default AuthenticatedRoutes;
