import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSearchDebounce, useService } from 'Hooks';
import FilterStore from 'Stores/FilterStore';
import { Button, Layout, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';
import TdWithImage from 'Components/td-with-image/td-with-image';
import TableFilters from 'Components/table-filters/table-filters';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { ALL_ROLES, DEFAULT_PAGE_SIZE } from 'Models/Constants';
import { CirclePlusSign, User } from 'Components/icons';
import { theme } from 'Style/theme';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { AdminUserDetailsDto } from 'Api/Features/Users/Dtos/AdminUserDetailsDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { UserService } from 'Services/UserService';
import Tag, { TagColor, TagSize } from 'Components/tag/tag';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { GetAdminUsersRequestDto } from 'Api/Features/Users/Dtos/GetAdminUsersRequestDto';
import { useHistory } from 'react-router-dom';
import EditAdministratorModal from './edit-administrator-modal';
import './index.less';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const Administrators: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const filterStoreRef = useRef(new FilterStore());
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [admins, setAdmins] = useState<AdminUserDetailsDto[]>([]);
    const userService = useService(UserService);
    const history = useHistory();
    const [administratorModalOpen, setAdministratorModalOpen] = useState(false);
    //#endregion

    //#region Table Content

    const columns: ColumnType<AdminUserDetailsDto>[] = [
        {
            key: GetUsersSortColumnDto.Name,
            title: t('name'),
            render: (admin: AdminUserDetailsDto): ReactNode | null => {
                return admin ? (
                    <div>
                        <TdWithImage
                            defaultImg={<User fill={theme['grayscale-300']} />}
                            imgSrc={admin?.imageUrl}
                        >
                            {`${admin?.firstName} ${admin.lastName}`}
                        </TdWithImage>
                    </div>
                ) : null;
            },
            sorter: true,
        },
        {
            key: GetUsersSortColumnDto.Email,
            title: t('email'),
            render: (admin: AdminUserDetailsDto): ReactNode | null => admin.email,
            sorter: true,
        },
        {
            key: GetUsersSortColumnDto.ManagementRole,
            title: t('role'),
            render: (admin: AdminUserDetailsDto): ReactNode | null => {
                const tagProps = {
                    text:
                        admin.managementRoles?.[0]?.name === ManagementRoleDto.SuperAdministrator
                            ? 'Super Administrator'
                            : admin.managementRoles?.[0]?.name,
                    color:
                        admin.managementRoles?.[0]?.name === ManagementRoleDto.SuperAdministrator
                            ? TagColor.Green
                            : TagColor.Purple,
                };
                return (
                    <Tag text={tagProps.text ?? ''} color={tagProps.color} size={TagSize.Small} />
                );
            },
            sorter: true,
        },
    ];

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            searchTerm: filterStoreRef.current.searchTerm,
            role: filterStoreRef.current.role,
        });
    };
    //#endregion

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetUsersSortColumnDto | null;
            sortDirection: SortDirection | null;
            searchTerm: string;
            role: string;
        }) => {
            setLoading(true);
            try {
                const request: GetAdminUsersRequestDto = {
                    pageSize: params.pagination.pageSize || DEFAULT_PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    managementRoles:
                        params.role === ALL_ROLES ? undefined : [ManagementRoleDto[params.role]],
                };

                // call api
                const [administrators, totalItems] = await userService.getAdminUsers(request);
                setAdmins(administrators);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [userService]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
                role: filterStore.role,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch]);
    //#endregion

    return (
        <div className="Administrators">
            <ListSectionHeader
                title={t('administrators')}
                icon={<User fill={theme['primary-color']} width={32} height={32} />}
                action={
                    <Button
                        onClick={(): void => {
                            setAdministratorModalOpen(true);
                        }}
                        type="default"
                        className="secondary"
                    >
                        <div className="add-btn">
                            <div className="text">
                                {t('create_entity', { param1: t('administrator') })}
                            </div>
                            <CirclePlusSign fill={theme['primary-color']} width={32} height={32} />
                        </div>
                    </Button>
                }
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch includeRoles />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(admin: AdminUserDetailsDto): string => admin.id!}
                    dataSource={admins}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: AdminUserDetailsDto) => ({
                        onClick: (): void => {
                            history.push(`administrators/${row.id}`);
                        },
                    })}
                />
            </Content>
            {administratorModalOpen && (
                <EditAdministratorModal
                    visible={administratorModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success)
                            fetch({
                                pagination: initialPaginationState,
                                sortColumn: null,
                                sortDirection: null,
                                searchTerm: filterStoreRef.current.searchTerm,
                                role: filterStoreRef.current.role,
                            });
                        setAdministratorModalOpen(false);
                    }}
                />
            )}
        </div>
    );
});

export default Administrators;
