import React, { FunctionComponent } from 'react';
import MPNLogo from 'Assets/Images/logo-white.svg';
import MPNLogoRed from 'Assets/Images/logo-red.svg';
import { theme } from 'Style/theme';
import './policies.less';
import moment from 'moment';
import { MOMENT_YEAR_FORMAT } from 'Models/Constants';
import { useTranslation } from 'react-i18next';

const TermsOfUse: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="PrivacyPolicy">
            <div className="header-container">
                <div className="logo-container">
                    <img className="logo" src={MPNLogo} alt="logo" />
                </div>

                <div className="curved-line">
                    <svg viewBox="0 0 500 150">
                        <path
                            d="M-14.21,-10.36 C-47.00,43.91 470.47,51.80 528.05,-9.38 L500.00,0.00 L0.00,0.00 Z"
                            style={{ stroke: 'none', fill: theme['primary-color'] }}
                        ></path>
                    </svg>
                </div>
            </div>

            <div className="box-container">
                <div className="box Shadow-MPN">
                    <div className="H2-bold">{t('TOU.TOU_title')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p1')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p2')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p3')}</div>

                    <div className="H2-bold">{t('TOU.TOU_h1')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p4')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p5')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p6')}</div>

                    <div className="H2-bold">{t('TOU.TOU_h2')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p7')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p8')}</div>

                    <div className="H2-bold">{t('TOU.TOU_h3')}</div>
                    <div className="Paragraph">
                        {t('TOU.TOU_p9')}
                        <ol>
                            <li>{t('TOU.TOU_p10')}</li>
                            <li>{t('TOU.TOU_p11')}</li>
                            <li>{t('TOU.TOU_p12')}</li>
                            <li>{t('TOU.TOU_p13')}</li>
                            <li>{t('TOU.TOU_p14')}</li>
                            <li>{t('TOU.TOU_p15')}</li>
                            <li>{t('TOU.TOU_p16')}</li>
                            <li>{t('TOU.TOU_p17')}</li>
                            <li>{t('TOU.TOU_p18')}</li>
                            <li>{t('TOU.TOU_p19')}</li>
                            <li>{t('TOU.TOU_p20')}</li>
                            <li>{t('TOU.TOU_p21')}</li>
                            <li>{t('TOU.TOU_p22')}</li>
                            <li>{t('TOU.TOU_p23')}</li>
                            <li>{t('TOU.TOU_p24')}</li>
                            <li>{t('TOU.TOU_p25')}</li>
                        </ol>
                    </div>

                    <div className="H2-bold">{t('TOU.TOU_h4')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p26')}</div>
                    <div className="Paragraph">{t('TOU.TOU_p27')}</div>
                    <div className="Paragraph">
                        <ul>
                            <li>{t('TOU.TOU_p28')}</li>
                            <li>{t('TOU.TOU_p29')}</li>
                            <li>{t('TOU.TOU_p30')}</li>
                            <li>{t('TOU.TOU_p31')}</li>
                        </ul>
                    </div>

                    <div className="Paragraph">{t('TOU.TOU_p32')}</div>
                </div>
            </div>

            <div className="footer">
                <div className="copyright">{`© ${moment().format(
                    MOMENT_YEAR_FORMAT
                )} MPNGENIE Inc. ${t('all_rights_reserved')}`}</div>
                <img className="logo" src={MPNLogoRed} alt="logo" />
            </div>
        </div>
    );
};

export default TermsOfUse;
