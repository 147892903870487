import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { UpdatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/UpdatePhysicianRequestDto';
import { UpdateAdminUserRequestDto } from 'Api/Features/Users/Dtos/UpdateAdminUserRequestDto';
import { UserRequestRoleDto } from 'Api/Features/Users/Dtos/UserRequestRoleDto';
import BaseModal from 'Components/base-modal/base-modal';
import ImagePicker from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditAdministratorProfileSchema, EditDoctorProfileSchema } from 'Schemas/EditProfileSchema';
import { PhysicianService } from 'Services/PhysicianService';
import { UserService } from 'Services/UserService';
import { ManagerUserDetailsExtended } from 'Stores/UserStore';
import { SUPPORTED_LANGS } from 'Models/Constants';
import Select, { SelectOption } from 'Components/select/select';
import { observer } from 'mobx-react';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditProfileModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    isDoctor: boolean;
    user: ManagerUserDetailsExtended;
}

const EditProfile: FunctionComponent<EditProfileModalProps> = observer(
    ({ visible, onComplete, isDoctor, user }) => {
        //#region Hooks
        const { t } = useTranslation();
        const [errors, validateForm, resetErrors] = useFormValidation(
            isDoctor ? EditDoctorProfileSchema : EditAdministratorProfileSchema
        );
        const { globalLoadingStore, toastStore, confirmationModalStore, languageStore } =
            useStores();
        const [form] = Form.useForm();
        const physicianService = useService(PhysicianService);
        const userService = useService(UserService);
        const [imageUrl, setImageUrl] = useState<string | undefined>(user.imageUrl ?? undefined);
        const [base64, setBase64] = useState<string>();
        //#endregion

        //#region Effects
        useEffect(() => {
            if (user) {
                form.setFieldsValue({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    lang: languageStore.currentLanguage,
                });
            }
        }, [user, form, languageStore.currentLanguage]);
        //#endregion

        //#region Submit / Exit
        const dismiss = (success = false): void => {
            onComplete(success);
            form.resetFields();
            resetErrors();
        };

        const exit = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: null,
                    title: t('ConfirmModal.confirm_modal_title'),
                    message: t('ConfirmModal.confirm_modal_message'),
                    positiveText: t('yes'),
                    negativeText: t('no'),
                }))
            )
                return;
            dismiss();
        };

        const submit = async (): Promise<void> => {
            const data = form.getFieldsValue();

            if (!(await validateForm(data))) return;
            try {
                globalLoadingStore.addLoading();

                languageStore.setCurrentLanguage(data.lang);

                if (isDoctor) {
                    const resquest: UpdatePhysicianRequestDto = {
                        ...data,
                        image: base64
                            ? ({
                                  uploadBase64: base64,
                              } as UpdateFileRequestDto)
                            : null,
                    };
                    await physicianService.updatePhysician(user.id!, resquest);
                } else {
                    const resquest: UpdateAdminUserRequestDto = {
                        ...data,
                        image: base64
                            ? ({
                                  uploadBase64: base64,
                              } as UpdateFileRequestDto)
                            : null,
                        managementRoles: [
                            {
                                name: user.managementRoles?.[0]?.name,
                            } as UserRequestRoleDto,
                        ],
                    };
                    await userService.updateAdminUser(user.id!, resquest);
                }

                toastStore.toast({
                    type: 'success',
                    messageKey: t('Toast.toast_entity_action_success', {
                        param1: t('profile'),
                        param2: t('action_updated'),
                    }),
                });

                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#endregion

        //#region Render
        return (
            <BaseModal
                visible={visible}
                title={t('edit_entity', { param1: t('profile') })}
                className="FormModal"
                onCancel={exit}
            >
                <div className="EditProfile">
                    <Form layout="vertical" onFinish={submit} form={form}>
                        <ImagePicker
                            onFileChange={(imageUrl, base64) => {
                                setImageUrl(imageUrl);
                                setBase64(base64);
                            }}
                            imageUrl={imageUrl}
                        />

                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="firstName"
                                    label={t('first_name')}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="lastName"
                                    label={t('last_name')}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem errors={errors} name="email" label={t('email')}>
                                    <Input disabled />
                                </ValidatedFormItem>
                            </Col>
                            {isDoctor && (
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="phoneNumber"
                                        label={t('phone_number')}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            )}
                        </Row>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="lang"
                                    label={t('console_language')}
                                >
                                    <Select
                                        defaultValue={languageStore.currentLanguage}
                                        options={SUPPORTED_LANGS.map(
                                            (lang) =>
                                                ({
                                                    id: lang,
                                                    name: t(`Lang.lang_${lang}`),
                                                } as SelectOption)
                                        )}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <div className="actions">
                            <Button
                                type="default"
                                className="secondary negative"
                                htmlType="button"
                                onClick={(): Promise<void> => exit()}
                            >
                                {t('cancel')}
                            </Button>
                            <Button type="primary" className="positive" htmlType="submit">
                                {t('save_changes')}
                            </Button>
                        </div>
                    </Form>
                </div>
            </BaseModal>
        );
        //#endregion
    }
);

export default React.memo(EditProfile);
