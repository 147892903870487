import React, { FunctionComponent, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import './index.less';

//https://swiperjs.com/react

interface SideSwippingCarousel {
    elements: ReactNode[];
    spaceBetweenElements?: number
}

const SideSwippingCarousel: FunctionComponent<SideSwippingCarousel> = ({
    elements,
    spaceBetweenElements = 20
}) => {
    return (
        <div className="SideSwippingCarousel">
            <Swiper slidesPerView={'auto'} spaceBetween={spaceBetweenElements} freeMode observer>
                {elements.length > 0 && elements.map((element, i) => (
                    <SwiperSlide key={i} virtualIndex={i}>{element}</SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default React.memo(SideSwippingCarousel);

