import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const QuestionMarkCircle: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 16 17" fill="none" {...props}>
                <path
                    d="M8 0.0999756C3.58214 0.0999756 0 3.68212 0 8.09998C0 12.5178 3.58214 16.1 8 16.1C12.4179 16.1 16 12.5178 16 8.09998C16 3.68212 12.4179 0.0999756 8 0.0999756ZM8 14.7428C4.33214 14.7428 1.35714 11.7678 1.35714 8.09998C1.35714 4.43212 4.33214 1.45712 8 1.45712C11.6679 1.45712 14.6429 4.43212 14.6429 8.09998C14.6429 11.7678 11.6679 14.7428 8 14.7428Z"
                    fill={fill}
                />
                <path
                    d="M9.99307 4.61253C9.45735 4.14289 8.75021 3.88574 8.00021 3.88574C7.25021 3.88574 6.54307 4.14467 6.00735 4.61253C5.45021 5.10003 5.14307 5.75539 5.14307 6.45717V6.59289C5.14307 6.67146 5.20735 6.73574 5.28592 6.73574H6.14307C6.22164 6.73574 6.28592 6.67146 6.28592 6.59289V6.45717C6.28592 5.66967 7.05557 5.0286 8.00021 5.0286C8.94485 5.0286 9.7145 5.66967 9.7145 6.45717C9.7145 7.01253 9.32164 7.52146 8.71271 7.75539C8.33414 7.90003 8.01271 8.1536 7.78235 8.48574C7.54842 8.82503 7.427 9.23217 7.427 9.64467V10.0286C7.427 10.1072 7.49128 10.1715 7.56985 10.1715H8.427C8.50557 10.1715 8.56985 10.1072 8.56985 10.0286V9.62324C8.57078 9.44989 8.62392 9.28083 8.72234 9.13813C8.82077 8.99542 8.95992 8.88569 9.12164 8.82324C10.1752 8.41789 10.8556 7.48931 10.8556 6.45717C10.8574 5.75539 10.5502 5.10003 9.99307 4.61253ZM7.28592 12.0286C7.28592 12.218 7.36118 12.3997 7.49513 12.5337C7.62909 12.6676 7.81077 12.7429 8.00021 12.7429C8.18965 12.7429 8.37133 12.6676 8.50529 12.5337C8.63924 12.3997 8.7145 12.218 8.7145 12.0286C8.7145 11.8392 8.63924 11.6575 8.50529 11.5235C8.37133 11.3896 8.18965 11.3143 8.00021 11.3143C7.81077 11.3143 7.62909 11.3896 7.49513 11.5235C7.36118 11.6575 7.28592 11.8392 7.28592 12.0286Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default QuestionMarkCircle;
