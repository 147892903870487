import { GetSurveysRequestDto } from 'Api/Features/Surveys/Dtos/GetSurveysRequestDto';
import { GetSurveysResponseDto } from 'Api/Features/Surveys/Dtos/GetSurveysResponseDto';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import { SurveyProxy } from 'Api/Features/Surveys/SurveyProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(SurveyProxy)
export class SurveyService extends ApiService {
    constructor(private readonly surveyProxy: SurveyProxy) {
        super();
    }

    public async getSurveys(request: GetSurveysRequestDto | null): Promise<[SurveyDto[], number]> {
        const data: GetSurveysResponseDto | null = await this.surveyProxy.getSurveys(request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }
}
