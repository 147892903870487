import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const User: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 32 33" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5334 3.88002C15.2683 3.88002 14.055 4.38257 13.1605 5.27712C12.2659 6.17167 11.7634 7.38494 11.7634 8.65002C11.7634 9.91511 12.2659 11.1284 13.1605 12.0229C14.055 12.9175 15.2683 13.42 16.5334 13.42C17.7985 13.42 19.0117 12.9175 19.9063 12.0229C20.8008 11.1284 21.3034 9.91511 21.3034 8.65002C21.3034 7.38494 20.8008 6.17167 19.9063 5.27712C19.0117 4.38257 17.7985 3.88002 16.5334 3.88002ZM10.9119 3.02852C12.4028 1.5376 14.4249 0.700012 16.5334 0.700012C18.6419 0.700012 20.664 1.5376 22.1549 3.02852C23.6458 4.51943 24.4834 6.54155 24.4834 8.65002C24.4834 10.7585 23.6458 12.7806 22.1549 14.2715C20.664 15.7624 18.6419 16.6 16.5334 16.6C14.4249 16.6 12.4028 15.7624 10.9119 14.2715C9.42096 12.7806 8.58337 10.7585 8.58337 8.65002C8.58337 6.54155 9.42096 4.51943 10.9119 3.02852Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4416 21.6791C11.3917 21.6791 9.42579 22.4935 7.97628 23.943C6.52678 25.3925 5.71246 27.3584 5.71246 29.4083V30.9541C5.71246 31.8079 5.02037 32.5 4.16662 32.5C3.31288 32.5 2.62079 31.8079 2.62079 30.9541V29.4083C2.62079 26.5384 3.76084 23.7861 5.79014 21.7568C7.81945 19.7275 10.5718 18.5875 13.4416 18.5875H19.625C22.4949 18.5875 25.2472 19.7275 27.2765 21.7568C29.3058 23.7861 30.4458 26.5384 30.4458 29.4083V30.9541C30.4458 31.8079 29.7537 32.5 28.9 32.5C28.0463 32.5 27.3542 31.8079 27.3542 30.9541V29.4083C27.3542 27.3584 26.5398 25.3925 25.0903 23.943C23.6408 22.4935 21.6749 21.6791 19.625 21.6791H13.4416Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default User;
