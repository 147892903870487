import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CircleCheckmark: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 36 36" fill="none" {...props}>
                <path
                    d="M16.0224 25.7333C15.5183 25.7333 15.0408 25.509 14.7349 25.1223L10.5941 19.9063C10.466 19.7452 10.3726 19.5616 10.319 19.3661C10.2654 19.1705 10.2528 18.9668 10.2819 18.7666C10.311 18.5665 10.3811 18.3737 10.4884 18.1994C10.5956 18.025 10.7379 17.8726 10.907 17.7507C11.076 17.6283 11.2687 17.5389 11.4741 17.4876C11.6794 17.4364 11.8933 17.4243 12.1036 17.452C12.3138 17.4797 12.5163 17.5467 12.6994 17.6491C12.8824 17.7516 13.0425 17.8875 13.1703 18.049L15.8949 21.4785L22.7453 10.9911C22.9725 10.6449 23.3344 10.3986 23.7518 10.3065C24.1691 10.2143 24.6078 10.2837 24.9715 10.4995C25.7283 10.948 25.9613 11.8982 25.4884 12.6208L17.3946 25.0063C17.2564 25.2187 17.066 25.3957 16.8398 25.522C16.6136 25.6483 16.3585 25.7201 16.0966 25.7311C16.0711 25.7333 16.0479 25.7333 16.0224 25.7333Z"
                    fill={fill}
                />
                <rect x="1" y="1" width="34" height="34" rx="17" stroke={fill} strokeWidth="2" />
            </svg>
        </span>
    );
};

export default CircleCheckmark;
