import { PHONE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

export const EditDoctorProfileSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phoneNumber: yup.string().required('Required').matches(PHONE_REGEX, 'Not a valid phone number'),
});

export const EditAdministratorProfileSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'), 
});