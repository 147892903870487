import { HttpClient } from 'HttpClient/HttpClient';
import { AxiosRequestConfig } from 'axios';
import { inject } from 'aurelia-dependency-injection';
import { AuthenticationStore, AuthorizationStore, UserStore } from 'Stores';
import { TokenRequestDto } from 'Models/Authentication/TokenRequestDto';
import { AccessTokenGrantType } from 'Models/Authentication/AccessTokenGrantType';
import { TokenResponseDto } from 'Models/Authentication/TokenResponseDto';
import { AuthenticationProxy } from 'Api/Features/Authentication/AuthenticationProxy';
import { ApiService } from './ApiService';
import moment from 'moment';

@inject(
    HttpClient,
    AuthenticationStore,
    AuthenticationProxy,
    AuthorizationStore,
    UserStore
)
export class AuthenticationService extends ApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticationStore: AuthenticationStore,
        private readonly authenticationProxy: AuthenticationProxy,
        private readonly authorizationStore: AuthorizationStore,
        private readonly userStore: UserStore
    ) {
        super();
    }

    private refreshTokenPromise;

    public installInterceptors(): void {
        this.httpClient.addRequestInterceptor((requestConfig: AxiosRequestConfig) =>
            this.onBeforeRequest(requestConfig)
        );
    }

    public async initAccessToken(userName: string, password: string): Promise<void> {
        this.userStore.setCallInProgress(true);

        const request: TokenRequestDto = {
            grant_type: AccessTokenGrantType.password,
            username: userName,
            password: password,
            refresh_token: null,
        };
        const response: TokenResponseDto | null = await this.authenticationProxy.createAccessToken(
            request
        );
        if (response != null) {
            this.authenticationStore.setSession(response);
            try {
                setTimeout(() => {
                    // onBeforeRequest() needs to add token in request header before we can start to make calls.
                    // this.userStore.setUserInfo() would fire before the token would be set in header
                }, 1000)
                await this.userStore.setUserInfo();
                    if (this.userStore.userRole === undefined) {
                        this.authenticationStore.clearSession();
                        this.authorizationStore.handleUserCannotAccessSite();
                        this.userStore.clearUserInfo();
                    }
            }
            catch (e) {
                this.authenticationStore.clearSession();
                this.authorizationStore.handleUserCannotAccessSite();
                this.userStore.clearUserInfo();
            }
        }
        this.userStore.setCallInProgress(false);
    }

    public async refreshAccessToken(refreshToken: string): Promise<void> {
        const request: TokenRequestDto = {
            grant_type: AccessTokenGrantType.refresh_token,
            username: null,
            password: null,
            refresh_token: refreshToken,
        };

        const response: TokenResponseDto | null = await this.authenticationProxy.createAccessToken(
            request
        );
        if (response != null) {
            this.authenticationStore.setSession(response);
        }
    }

    public async refreshAccessTokenIfNeeded(): Promise<void> {
        //Retrieve refresh token from store.
        const refreshToken = this.authenticationStore.refreshToken;
        const tokenExpiration = this.authenticationStore.expirationTimeStamp;

        // No token currently set.
        if (!refreshToken || !tokenExpiration) {
            return;
        }

        if (Math.round(Date.now() / 1000) > tokenExpiration) {
            if(!this.refreshTokenPromise) {
                this.refreshTokenPromise = this.refreshAccessToken(refreshToken)
            }
            await this.refreshTokenPromise;
            this.refreshTokenPromise = null;
        }
    }

    public async onBeforeRequest(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        if (requestConfig.url !== '/token') {
            await this.refreshAccessTokenIfNeeded();
            //Retrieve access token from store.
            const accessToken = this.authenticationStore.accessToken;

            //Add Authorization header in request
            if (
                requestConfig.url !== '/users/forgot-password' &&
                requestConfig.url !== '/users/set-password'
            )
                requestConfig.headers.Authorization = `bearer ${accessToken}`;

            //add user current time in http header
            requestConfig.headers = { ...requestConfig.headers, 'App-Time': moment().format() };
        }
        return requestConfig;
    }
}
