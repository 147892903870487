import { Button, Dropdown, Menu } from 'antd';
import { ChevronDown, ChevronUp } from 'Components/icons';
import { observer } from 'mobx-react';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './dropdown-button.less';

interface DropdownButtonProps {
    menuItems: DropdownButtonMenuItem[];
}

export interface DropdownButtonMenuItem {
    text: string;
    icon: ReactNode;
    onClick: () => void;
}

const DropdownButton: FunctionComponent<DropdownButtonProps> = observer(({ menuItems }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const menuElement = (
        <Menu>
            {menuItems.map((item, i) => (
                <Menu.Item
                    key={i}
                    onClick={() => {
                        setIsOpen(false);
                        item.onClick();
                    }}
                >
                    <div className="DropdownButton-menu-item">
                        <div>{item.icon}</div>
                        <div className="text">{item.text}</div>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            className={`DropdownButton ${isOpen ? 'open' : ''}`}
            overlay={menuElement}
            trigger={['click']}
            onVisibleChange={(visible: boolean) => setIsOpen(visible)}
            placement="bottomLeft"
            overlayStyle={{ minWidth: '276px' }}
        >
            <Button type={isOpen ? 'primary' : 'default'} className={isOpen ? '' : 'secondary'}>
                {t('options')}
                {isOpen ? (
                    <ChevronUp fill={theme.white} />
                ) : (
                    <ChevronDown fill={theme['primary-color']} />
                )}
            </Button>
        </Dropdown>
    );
});

export default React.memo(DropdownButton);
