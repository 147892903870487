import { action, observable } from 'mobx';

export type SupportedLanguage = 'fr' | 'en' | 'de' | 'it' | 'es';

class LanguageStore {
    @observable
    public currentLanguage: SupportedLanguage = sessionStorage.lang ?? 'en';

    @action
    setCurrentLanguage(lang: SupportedLanguage): void {
        this.currentLanguage = sessionStorage.lang = lang;
    }
}

export default LanguageStore;
