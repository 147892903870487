import React, { FunctionComponent, useEffect } from 'react';
import { autorun } from 'mobx';
import { useStores } from '../../Hooks';
import { message } from 'antd';
import './toast-container.less'
import { Checkmark, Close } from 'Components/icons';
import { theme } from 'Style/theme';

const ToastContainer: FunctionComponent = () => {
    const { toastStore } = useStores();
    useEffect(
        () =>
            autorun(() => {
                if (toastStore.currentToast) {
                  message[toastStore.currentToast.type]({
                    content:
                      toastStore.currentToast.message ||
                      toastStore.currentToast.messageKey ||
                      "",
                    className: "custom-toast",
                    icon: toastStore.currentToast.type === 'success' ? <Checkmark fill={theme['white']} /> : <Close fill={theme['white']} />,
                    duration: 5
                  });
                }
            }),
        [toastStore.currentToast]
    );
    return null;
};

export default ToastContainer;
