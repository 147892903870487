import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSearchDebounce, useService } from 'Hooks';
import FilterStore from 'Stores/FilterStore';
import { Button, Layout, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';
import TdWithImage from 'Components/td-with-image/td-with-image';
import TableFilters from 'Components/table-filters/table-filters';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { DEFAULT_PAGE_SIZE } from 'Models/Constants';
import { CirclePlusSign, Stethoscope, User } from 'Components/icons';
import { theme } from 'Style/theme';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { PhysicianService } from 'Services/PhysicianService';
import { PhysicianDto } from 'Api/Features/Physicians/Dtos/PhysicianDto';
import { GetPhysiciansRequestDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansRequestDto';
import { GetPhysiciansSortColumnDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansSortColumnDto';
import { useHistory } from 'react-router-dom';
import Tag, { TagColor, TagSize } from 'Components/tag/tag';
import EditDoctorModal from './edit-doctor-modal';
import './index.less';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const Doctors: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const filterStoreRef = useRef(new FilterStore());
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [doctors, setDoctors] = useState<PhysicianDto[]>([]);
    const physicianService = useService(PhysicianService);
    const history = useHistory();
    const [createDoctorModalOpen, setCreateDoctorModalOpen] = useState(false);
    //#endregion

    //#region Table Content

    const columns: ColumnType<PhysicianDto>[] = [
        {
            key: GetPhysiciansSortColumnDto.LastName,
            title: t('name'),
            render: (doctor: PhysicianDto): ReactNode | null => {
                return doctor ? (
                    <TdWithImage
                        defaultImg={<User fill={theme['grayscale-300']} />}
                        imgSrc={doctor?.imageUrl}
                    >
                        <div>
                            {`${doctor?.firstName} ${doctor.lastName}`}{' '}
                            {!doctor.emailConfirmed ? (
                                <Tag
                                    size={TagSize.Small}
                                    text="Pending Invitation"
                                    color={TagColor.Grey}
                                />
                            ) : null}
                        </div>
                    </TdWithImage>
                ) : null;
            },
            sorter: true,
        },
        {
            key: GetPhysiciansSortColumnDto.Email,
            title: t('email'),
            render: (doctor: PhysicianDto): ReactNode | null => doctor.email,
            sorter: true,
        },
        {
            key: GetPhysiciansSortColumnDto.PhoneNumber,
            title: t('phone_number'),
            render: (doctor: PhysicianDto): ReactNode => doctor.phoneNumber,
            sorter: true,
        },
    ];

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            searchTerm: filterStoreRef.current.searchTerm,
        });
    };
    //#endregion

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetPhysiciansSortColumnDto | null;
            sortDirection: SortDirection | null;
            searchTerm: string;
        }) => {
            setLoading(true);
            try {
                const request: GetPhysiciansRequestDto = {
                    pageSize: params.pagination.pageSize || DEFAULT_PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                };

                // call api
                const [doctors, totalItems] = await physicianService.getPhysicians(request);
                setDoctors(doctors);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [physicianService]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch]);
    //#endregion

    return (
        <div className="Doctors">
            <ListSectionHeader
                title={t('doctors')}
                icon={<Stethoscope fill={theme['primary-color']} width={32} height={32} />}
                action={
                    <Button
                        onClick={(): void => {
                            setCreateDoctorModalOpen(true);
                        }}
                        type="default"
                        className="secondary"
                    >
                        <div className="add-btn">
                            <div className="text">
                                {t('create_entity', { param1: t('doctor') })}
                            </div>
                            <CirclePlusSign fill={theme['primary-color']} width={32} height={32} />
                        </div>
                    </Button>
                }
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(doctor: PhysicianDto): string => doctor.id!}
                    dataSource={doctors}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: PhysicianDto) => ({
                        onClick: (): void => {
                            history.push(`doctors/${row.id}`);
                        },
                    })}
                />
            </Content>
            {createDoctorModalOpen && (
                <EditDoctorModal
                    visible={createDoctorModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success)
                            fetch({
                                pagination: initialPaginationState,
                                sortColumn: null,
                                sortDirection: null,
                                searchTerm: filterStoreRef.current.searchTerm,
                            });
                        setCreateDoctorModalOpen(false);
                    }}
                />
            )}
        </div>
    );
});

export default Doctors;
