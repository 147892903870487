export enum MPNSymptom {
    Fatigue = 'Fatigue',
    FillingUpQuickly = 'Filling up quickly',
    AbdominalDiscomfort = 'Abdominal discomfort',
    Inactivity = 'Inactivity',
    ProblemsWithConcentration = 'Problems with concentration',
    NightSweats = 'Night sweats',
    Itching = 'Itching (Pruritus)',
    BonePain = 'Bone pain',
    Fever = 'Fever',
    UnintentionalWeightLoss = 'Unintentional weight loss',
}

export enum SymptomDropdownExtendedMPNSymptomEnum {
    TotalScore ='Total score',
    Fatigue = 'Fatigue',
    FillingUpQuickly = 'Filling up quickly',
    AbdominalDiscomfort = 'Abdominal discomfort',
    Inactivity = 'Inactivity',
    ProblemsWithConcentration = 'Problems with concentration',
    NightSweats = 'Night sweats',
    Itching = 'Itching (Pruritus)',
    BonePain = 'Bone pain',
    Fever = 'Fever',
    UnintentionalWeightLoss = 'Unintentional weight loss',
}

export const Mpn10QuestionMap = {
    Fatigue: '971d4aa1-6b35-4ad6-810c-6c9a567e5537',
    FillingUpQuickly: 'a265dcba-ca64-4942-922e-4e75437968d9',
    AbdominalDiscomfort: '2a1fb687-c07c-4aa1-b0f2-a27e72f90b32',
    Inactivity: '2c80fc4e-083d-43e5-b13d-c481424b6634',
    ProblemsWithConcentration: '1b77f614-85c9-4f3b-b4c9-010f67f18b68',
    NightSweats: '0e457c8c-c8b1-4e38-98cd-decca74ff5e2',
    Itching: '4f04675a-1232-4147-971d-db56af9552a0',
    BonePain: '2e689053-3337-4407-9111-212d5c644a27',
    Fever: '73a026ca-80f9-4914-aaa1-1e196f515844',
    UnintentionalWeightLoss: '7faa2fa3-3673-448f-a5f2-be0b60d9e8e0',
};

export const Mpn10StringQuestionMap = {
    Fatigue: '971d4aa1-6b35-4ad6-810c-6c9a567e5537',
    'Filling up quickly': 'a265dcba-ca64-4942-922e-4e75437968d9',
    'Abdominal discomfort': '2a1fb687-c07c-4aa1-b0f2-a27e72f90b32',
    Inactivity: '2c80fc4e-083d-43e5-b13d-c481424b6634',
    'Problems with concentration': '1b77f614-85c9-4f3b-b4c9-010f67f18b68',
    'Night sweats': '0e457c8c-c8b1-4e38-98cd-decca74ff5e2',
    'Itching (Pruritus)': '4f04675a-1232-4147-971d-db56af9552a0',
    'Bone pain': '2e689053-3337-4407-9111-212d5c644a27',
    Fever: '73a026ca-80f9-4914-aaa1-1e196f515844',
    'Unintentional weight loss': '7faa2fa3-3673-448f-a5f2-be0b60d9e8e0',
};

export interface Mpn10QuestionMapInterface {
    Title: MPNSymptom,
    Id: string
}