// GENERATED FILE - DO NOT MODIFY
import { AnswerPhysicianRequestsRequestDto } from 'Api/Features/Physicians/Dtos/AnswerPhysicianRequestsRequestDto';
import { CreatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/CreatePhysicianRequestDto';
import { GetPhysiciansRequestDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansRequestDto';
import { GetPhysiciansResponseDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansResponseDto';
import { PhysicianDetailsDto } from 'Api/Features/Physicians/Dtos/PhysicianDetailsDto';
import { TransferPhysicianPatientsRequestDto } from 'Api/Features/Physicians/Dtos/TransferPhysicianPatientsRequestDto';
import { UpdatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/UpdatePhysicianRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PhysicianProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPhysician(physicianId: string): Promise<PhysicianDetailsDto | null> {
        const uri = this.buildUri(
            "/physicians/{physicianId}",
            { physicianId: physicianId },
            null
        );

        const data: PhysicianDetailsDto | null = await this.httpClient.get<PhysicianDetailsDto | null>(uri);
        return data;
    }

    public async getPhysicians(request: GetPhysiciansRequestDto | null): Promise<GetPhysiciansResponseDto | null> {
        const uri = this.buildUri(
            "/physicians",
            null,
            { ...request || undefined }
        );

        const data: GetPhysiciansResponseDto | null = await this.httpClient.get<GetPhysiciansResponseDto | null>(uri);
        return data;
    }

    public async createPhysician(request: CreatePhysicianRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/physicians",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreatePhysicianRequestDto | null, string>(uri, request);
        return data;
    }

    public async updatePhysician(physicianId: string, request: UpdatePhysicianRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/physicians/{physicianId}",
            { physicianId: physicianId },
            null
        );

        await this.httpClient.patch<UpdatePhysicianRequestDto | null, void>(uri, request);
    }

    public async deletePhysician(physicianId: string): Promise<void> {
        const uri = this.buildUri(
            "/physicians/{physicianId}",
            { physicianId: physicianId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async transferPhysicianPatients(physicianId: string, request: TransferPhysicianPatientsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/physicians/{physicianId}/transfer-patients",
            { physicianId: physicianId },
            null
        );

        await this.httpClient.put<TransferPhysicianPatientsRequestDto | null, void>(uri, request);
    }

    public async answerPhysicianRequests(physicianId: string, request: AnswerPhysicianRequestsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/physicians/{physicianId}/answer-requests",
            { physicianId: physicianId },
            null
        );

        await this.httpClient.post<AnswerPhysicianRequestsRequestDto | null, void>(uri, request);
    }
}