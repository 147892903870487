// GENERATED FILE - DO NOT MODIFY
import { CreateSurveyResponseRequestDto } from 'Api/Features/SurveyResponses/Dtos/CreateSurveyResponseRequestDto';
import { CreateSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/CreateSurveyResponsesRequestDto';
import { CreateSurveyResponsesResponseDto } from 'Api/Features/SurveyResponses/Dtos/CreateSurveyResponsesResponseDto';
import { DeleteSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/DeleteSurveyResponsesRequestDto';
import { ExportSurveyResponseReportRequestDto } from 'Api/Features/SurveyResponses/Dtos/ExportSurveyResponseReportRequestDto';
import { GetSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesRequestDto';
import { GetSurveyResponsesResponseDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesResponseDto';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SurveyResponseProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSurveyResponse(surveyResponseId: string): Promise<SurveyResponseDto | null> {
        const uri = this.buildUri(
            "/survey-responses/{surveyResponseId}",
            { surveyResponseId: surveyResponseId },
            null
        );

        const data: SurveyResponseDto | null = await this.httpClient.get<SurveyResponseDto | null>(uri);
        return data;
    }

    public async getSurveyResponses(request: GetSurveyResponsesRequestDto | null): Promise<GetSurveyResponsesResponseDto | null> {
        const uri = this.buildUri(
            "/survey-responses",
            null,
            { ...request || undefined }
        );

        const data: GetSurveyResponsesResponseDto | null = await this.httpClient.get<GetSurveyResponsesResponseDto | null>(uri);
        return data;
    }

    public async createSurveyResponse(request: CreateSurveyResponseRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/survey-responses",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateSurveyResponseRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteSurveyResponse(surveyResponseId: string): Promise<void> {
        const uri = this.buildUri(
            "/survey-responses/{surveyResponseId}",
            { surveyResponseId: surveyResponseId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async createSurveyResponses(request: CreateSurveyResponsesRequestDto | null): Promise<CreateSurveyResponsesResponseDto | null> {
        const uri = this.buildUri(
            "/survey-responses/batch",
            null,
            null
        );

        const data: CreateSurveyResponsesResponseDto | null = await this.httpClient.post<CreateSurveyResponsesRequestDto | null, CreateSurveyResponsesResponseDto | null>(uri, request);
        return data;
    }

    public async deleteSurveyResponses(request: DeleteSurveyResponsesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/survey-responses/batch",
            null,
            null
        );

        await this.httpClient.delete<DeleteSurveyResponsesRequestDto | null, void>(uri, request);
    }

    public async exportSurveyResponseReport(request: ExportSurveyResponseReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/survey-response-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}