import React, { FunctionComponent, ReactNode } from 'react';
import './list-section-header.less';

interface ListSectionHeaderProps {
    title?: string;
    icon?: ReactNode;
    action?: ReactNode;
}

const ListSectionHeader: FunctionComponent<ListSectionHeaderProps> = ({ title, icon, action }) => {
    return (
        <div className="ListSectionHeader">
            <div className="header-info">
                {icon && icon}
                {title && <div className="header-title H4-bold">{title}</div>}
            </div>
            {action && <div className="header-action">{action}</div>}
        </div>
    );
};

export default ListSectionHeader;
