import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Checkmark: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M6.02242 15.7334C5.51829 15.7334 5.04082 15.5091 4.73486 15.1224L0.594063 9.90633C0.466041 9.74525 0.372578 9.56168 0.319015 9.36614C0.265451 9.1706 0.25284 8.9669 0.2819 8.76671C0.31096 8.56651 0.381123 8.37374 0.488376 8.19942C0.59563 8.02509 0.737872 7.87263 0.90697 7.75074C1.07601 7.62835 1.26872 7.53896 1.47406 7.48771C1.6794 7.43645 1.89333 7.42433 2.10359 7.45204C2.31386 7.47975 2.51631 7.54675 2.69937 7.6492C2.88242 7.75165 3.04246 7.88753 3.17033 8.04906L5.89494 11.4786L12.7453 0.991177C12.9725 0.644912 13.3344 0.398689 13.7518 0.306518C14.1691 0.214347 14.6078 0.283753 14.9716 0.499512C15.7283 0.948087 15.9613 1.89827 15.4884 2.62085L7.39457 15.0064C7.2564 15.2188 7.06596 15.3958 6.83977 15.5221C6.61357 15.6484 6.35847 15.7202 6.09659 15.7312C6.07109 15.7334 6.04791 15.7334 6.02242 15.7334Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Checkmark;
