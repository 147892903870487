import { Modal, Typography } from 'antd';
import { QuestionTypeDto } from 'Api/Features/SurveyResponses/Dtos/QuestionTypeDto';
import { QuestionDto } from 'Api/Features/Surveys/Dtos/QuestionDto';
import { Close } from 'Components/icons';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import { getLangQuestionDtoField } from 'Utils/SurveyResponseDtoUtils';
import './survey-question-explanation-modal.less';

const { Title } = Typography;

export interface ConfirmationModalProps {
    question?: QuestionDto;
    onClose: () => void;
    visible: boolean;
}

const SurveyQuestionExplanationModal: FunctionComponent<ConfirmationModalProps> = observer(
    ({ question, onClose, visible }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();
        return (
            <Modal
                visible={visible}
                className="SurveyQuestionExplanationModal"
                width={780}
                onCancel={onClose}
                closeIcon={<Close fill={theme.black} />}
            >
                <Title level={2} className="title">
                    {question?.[`title${getLangQuestionDtoField(languageStore.currentLanguage)}`]}
                </Title>
                <div className="description">
                    {
                        question?.[
                            `description${getLangQuestionDtoField(languageStore.currentLanguage)}`
                        ]
                    }
                </div>

                {question?.type === QuestionTypeDto.Likert && (
                    <div className="scale-container">
                        <div className="scale">
                            <div className="number">{'0'}</div>
                            <div className="text">{t('(absent)')}</div>
                        </div>

                        <div className="up-to">{t('up_to')}</div>

                        <div className="scale">
                            <div className="number">{'10'}</div>
                            <div className="text">{t('(worst_imaginable)')}</div>
                        </div>
                    </div>
                )}

                {(question?.type === QuestionTypeDto.MultiChoice ||
                    question?.type === QuestionTypeDto.SingleChoice) && (
                    <div className="possible-answer-container">
                        <ul>
                            {question?.choices?.map((x) => (
                                <li key={x?.id}>
                                    {
                                        x?.[
                                            `description${getLangQuestionDtoField(
                                                languageStore.currentLanguage
                                            )}`
                                        ]
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal>
        );
    }
);

export default SurveyQuestionExplanationModal;
