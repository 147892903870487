import { ConfigProvider } from 'antd';
import ConfirmationModalContainer from 'Components/confirmation-modal/confirmation-modal-container';
import GlobalLoadingContainer from 'Components/global-loading-container/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { useService } from 'Hooks/use-service';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationService } from 'Services/AuthenticationService';
import Routes from '../Routes';
import './App.less';
import fr_CA from 'antd/es/locale/fr_CA';
import en_US from 'antd/es/locale/en_US';
import de_DE from 'antd/es/locale/de_DE';
import it_IT from 'antd/es/locale/it_IT';
import es_ES from 'antd/es/locale/es_ES';
import { useStores } from 'Hooks';
import { Locale } from 'antd/es/locale-provider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';

const App: FunctionComponent = observer(() => {
    const { languageStore } = useStores();
    const authenticationService = useService(AuthenticationService);
    const [currentLanguage, setCurrentLanguage] = useState<Locale | undefined>(undefined);
    const { i18n } = useTranslation();

    useLayoutEffect(() => {
        authenticationService.installInterceptors();
    }, [authenticationService]);

    useEffect(() => {
        //Triggered on language change or first render. Set appropriate local
        switch (languageStore.currentLanguage) {
            case 'de':
                setCurrentLanguage(de_DE);
                i18n.changeLanguage('de');
                moment.locale('de');
                break;
            case 'en':
                setCurrentLanguage(en_US);
                i18n.changeLanguage('en');
                moment.locale('en');
                break;
            case 'fr':
                setCurrentLanguage(fr_CA);
                i18n.changeLanguage('fr');
                moment.locale('fr');
                break;
            case 'it':
                setCurrentLanguage(it_IT);
                i18n.changeLanguage('it');
                moment.locale('it');
                break;
            case 'es':
                setCurrentLanguage(es_ES);
                i18n.changeLanguage('es');
                moment.locale('es');
                break;
        }
    }, [languageStore.currentLanguage]);

    return (
        <BrowserRouter>
            <ConfigProvider locale={currentLanguage}>
                <Routes />
                <ToastContainer />
                <ConfirmationModalContainer />
                <GlobalLoadingContainer />
            </ConfigProvider>
        </BrowserRouter>
    );
});

export default App;
