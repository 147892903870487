import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import './change-email-confirmed.less';
import { useTranslation } from 'react-i18next';
import { useService } from 'Hooks';
import { ChangeEmailConfirmationAnonymousRequestDto } from 'Api/Features/Users/Dtos/ChangeEmailConfirmationAnonymousRequestDto';
import { UserService } from 'Services/UserService';

const ChangeEmailConfirmed: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const userService = useService(UserService);

    async function confirmChangeEmail(): Promise<void> {
        const urlParams = new URLSearchParams(window.location.search);
        const userId = urlParams.get('user-id') ?? '';
        const request: ChangeEmailConfirmationAnonymousRequestDto = {
            userId: userId,
            newEmail: urlParams.get('email'),
            token: urlParams.get('token'),
        };

        await userService.confirmChangeEmail(request);
    }

    confirmChangeEmail();

    return (
        <div className="ChangeEmail">
            <Row className="heading">
                <Col className="H2-bold">{t('ConsoleChangeEmailConfirmed.email_changed')}</Col>
            </Row>

            <Row className="heading">
                <Col className="Paragraph">{t('ConsoleChangeEmailConfirmed.email_account_updated')}</Col>
            </Row>
        </div>
    );
});


export default ChangeEmailConfirmed;
