import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const List: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2238 20.0815H4.91193C3.15779 20.0815 1.73944 18.6015 1.73944 16.7711V3.31043C1.73944 1.48001 3.15779 0 4.91193 0H15.2238C16.9779 0 18.3963 1.48001 18.3963 3.31043V16.7711C18.3963 18.6015 16.9779 20.0815 15.2238 20.0815ZM4.91193 18.1265H15.2238C15.9432 18.1265 16.5228 17.5218 16.5228 16.7711V3.31043C16.5228 2.55971 15.9432 1.95498 15.2238 1.95498H4.91193C4.1925 1.95498 3.61296 2.55971 3.61296 3.31043V16.7711C3.61296 17.5218 4.1925 18.1265 4.91193 18.1265Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.09375 7.98179C6.09375 7.44194 6.51315 7.0043 7.03051 7.0043H11.1073C11.6246 7.0043 12.044 7.44194 12.044 7.98179C12.044 8.52164 11.6246 8.95928 11.1073 8.95928H7.03051C6.51315 8.95928 6.09375 8.52164 6.09375 7.98179Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.09387 12.1004C6.09387 11.5606 6.51327 11.1229 7.03063 11.1229H13.1058C13.6232 11.1229 14.0426 11.5606 14.0426 12.1004C14.0426 12.6403 13.6232 13.0779 13.1058 13.0779H7.03063C6.51327 13.0779 6.09387 12.6403 6.09387 12.1004Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default List;
