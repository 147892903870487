import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './index.less';
import { useStores } from 'Hooks';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { useTranslation } from 'react-i18next';

const NotFoundPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    return (
        <div className="NotFoundPage">
            <div className="e404">{'404'}</div>
            <div className="H1-bold not-found">{t('Page404.page_404_title')}</div>
            <div className="Paragraph text">{t('Page404.page_404')}</div>
            <Button type="primary" className="back-to-site">
                <Link
                    to={
                        userStore.userRole === ManagementRoleDto.Physician
                            ? '/patients'
                            : '/doctors'
                    }
                >
                    {t('go_back')}
                </Link>
            </Button>
        </div>
    );
};

export default NotFoundPage;
