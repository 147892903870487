import React, { FunctionComponent } from 'react';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import './patient-header.less';
import { ReactNode } from 'react';
import Tag, { TagColor, TagSize } from 'Components/tag/tag';
import { Skeleton } from 'antd';
import LastMPNAnswerBadge from 'Components/last-mpn-answer-badge/last-mpn-answer-badge';
import { PhysicianPatientStatusDto } from 'Api/Features/Patients/Dtos/PhysicianPatientStatusDto';
import { DiagnosisToString } from 'Models/Diagnosis/DiagnosisToString';
import { TreatmentTypeToString } from 'Models/TreatmentType/TreatmentTypeToString';
import { useTranslation } from 'react-i18next';

interface PatientHeaderProps {
    patient?: PatientDto;
    action?: ReactNode;
    returnButton?: ReactNode;
    loading?: boolean;
}

const PatientHeader: FunctionComponent<PatientHeaderProps> = ({
    patient,
    action,
    returnButton,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <div className="PatientHeader">
            <Skeleton loading={loading} active>
                <div className="header-info">
                    {returnButton}
                    {patient && (
                        <div className="patient-card-container">
                            <div className="card-information">
                                <div className="H4-bold">
                                    {patient.firstName} {patient.lastName}
                                </div>
                                <div className="treatment-container">
                                    {patient.diagnosis && (
                                        <div className="Paragraph">
                                            {DiagnosisToString(patient.diagnosis, t)}
                                        </div>
                                    )}
                                    {patient.diagnosis && <div className="line" />}

                                    {patient.treatmentType && (
                                        <div className="Paragraph">
                                            {TreatmentTypeToString(patient.treatmentType, t)}
                                        </div>
                                    )}
                                </div>

                                <div className="tags-container">
                                    <LastMPNAnswerBadge
                                        displayDate
                                        lastAnswerDate={patient.lastSubmissionDate ?? undefined}
                                    />

                                    {patient.physicianPatientStatus ===
                                    PhysicianPatientStatusDto.PatientInvitationPending ? (
                                        <Tag
                                            text={t('pending_invitation')}
                                            color={TagColor.Grey}
                                            size={TagSize.Small}
                                        />
                                    ) : null}
                                </div>
                            </div>

                            <div className="right-side-card-information">
                                <div>
                                    <span className="Paragraph-small">{t('year_of_birth')}:</span>
                                    <span className="Paragraph-bold">
                                        {patient.birthYear ?? undefined}
                                    </span>
                                </div>
                                <div>
                                    <span className="Paragraph-small">{t('email')}:</span>
                                    <span className="Paragraph-bold">{patient.email}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {action && <div className="header-action">{action}</div>}
                </div>
            </Skeleton>
        </div>
    );
};

export default PatientHeader;
