import { Button, Col, Form, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import Title from 'antd/lib/typography/Title';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { ExportPatientStatisticsReportRequestDto } from 'Api/Features/Patients/Dtos/ExportPatientStatisticsReportRequestDto';
import { GetSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesRequestDto';
import { GetSurveyResponsesSortColumnDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesSortColumnDto';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import BaseModal from 'Components/base-modal/base-modal';
import { DateRangeDropdownOption, getMinDateFilter } from 'Components/date-range-dropdown';
import { Close } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { MOMENT_WRITTEN_MONTH_DAY_YEAR_FORMAT, MPN10_SURVEY_ID } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { PatientService } from 'Services/PatientService';
import { SurveyResponseService } from 'Services/SurveyResponseService';
import { SurveyService } from 'Services/SurveyService';
import { theme } from 'Style/theme';
import { showFile } from 'Utils/Blob';
import './print-statistics-modal.less';
import Chart from 'Components/chart/chart';
import { SymptomDropdownExtendedMPNSymptomEnum } from 'Models/Mpn10Question/Mpn10QuestionMap';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { useTranslation } from 'react-i18next';
import { getLangQuestionDtoField } from 'Utils/SurveyResponseDtoUtils';
import { observer } from 'mobx-react-lite';

interface TransferPatientModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    patientId: string;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const PrintStatisticsModal: FunctionComponent<TransferPatientModalProps> = observer(
    ({ visible, onComplete, patientId }) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();
        const surveyService = useService(SurveyService);
        const surveyResponseService = useService(SurveyResponseService);
        const patientService = useService(PatientService);
        const { confirmationModalStore, globalLoadingStore, toastStore, languageStore } =
            useStores();
        const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

        const [graphCheckboxState, setGraphCheckboxState] = useState(false);
        const [graphData, setGraphData] = useState<SurveyResponseDto[]>([]);
        const [graphBase64, setGraphBase64] = useState<string>();
        const graphRef = useRef<React.MutableRefObject<any>>();

        const pageSize = 25;
        const [surveysCurrentPage, setSurveysCurrentPage] = useState(0);
        const [surveysSearchResults, setSurveysSearchResults] = useState<SurveyDto[]>([]);
        const [surveysSearchTerm, setSurveysSearchTerm] = useState('');
        const [surveysMaxResults, setSurveysMaxResults] = useState(false);
        const [surveyOptions, setSurveysOptions] = useState<SelectCustomOption[]>([]);
        const [selectedSurveyOptions, setSelectedSurveysOptions] = useState<SelectCustomOption[]>(
            []
        );
        const [selectedSurveyIds, setSelectedSurveyIds] = useState<string[]>([]);

        const [surveyResponseOptions, setSurveyReponseOptions] = useState<SelectCustomOption[]>([]);
        const [selectedSurveyResponseOptions, setSelectedSurveyResponseOptions] = useState<
            SelectCustomOption[]
        >([]);
        const [selectedSurveyResponseIds, setSelectedSurveyResponseIds] = useState<string[]>([]);
        const [surveyResponseSearchResults, setSurveyResponseSearchResults] = useState<
            SurveyResponseDto[]
        >([]);
        const [selectLoading, setSelectLoading] = useState(false);
        const [selectAll, setSelectAll] = useState(false);

        const resetSurveysSearch = (): void => {
            setSurveysCurrentPage(0);
            setSurveysSearchResults([]);
            setSurveysSearchTerm('');
            setSurveysMaxResults(false);
        };

        const handleSurveyKeywordsChange = useCallback((value: string): void => {
            resetSurveysSearch();
            setSurveysSearchTerm(value);
        }, []);

        const searchSurveys = async (page: number, searchTerm: string): Promise<SurveyDto[]> => {
            const args = {
                pageSize: pageSize,
                page: page,
                searchTerm: searchTerm,
            };
            const [results, totalItemCount] = await surveyService.getSurveys(args);

            if (results.length + pageSize * page >= totalItemCount) {
                setSurveysMaxResults(true);
            }
            setSelectLoading(false);
            return results;
        };

        const debounceSurveySearch = useRef(
            debounce((page: number, searchTerm: string) => {
                searchSurveys(page, searchTerm).then((results) => {
                    setSurveysSearchResults((prevResults) => [...prevResults, ...results]);
                });
            }, 300)
        );

        const handleSurveysMenuScrollToBottom = (): void => {
            if (!surveysMaxResults) {
                setSurveysCurrentPage((prevPage) => prevPage + 1);
            }
        };

        useEffect(() => {
            const searchResults = surveysSearchResults?.map(
                (x: SurveyDto) =>
                    ({
                        value: x?.id,
                        label: x?.[
                            `title${getLangQuestionDtoField(languageStore.currentLanguage)}`
                        ],
                    } as SelectCustomOption)
            );

            const merged = mergeSelectedOptionsWithSearchResults(
                searchResults,
                selectedSurveyOptions
            );

            setSurveysOptions(merged);
        }, [surveysSearchResults, selectedSurveyOptions]);

        useEffect(() => {
            setSelectLoading(true);
            debounceSurveySearch.current(surveysCurrentPage, surveysSearchTerm);
        }, [surveysCurrentPage, surveysSearchTerm]);

        const searchSurveyResponses = async (surveyId: string): Promise<void> => {
            const args: GetSurveyResponsesRequestDto = {
                pageSize: 10,
                page: 0,
                surveyIds: [surveyId],
                sortColumn: GetSurveyResponsesSortColumnDto.Date,
                sortDirection: SortDirection.Descending,
                patientIds: [patientId],
            };
            const [results] = await surveyResponseService.getSurveyResponses(args);

            setSelectLoading(false);
            setSurveyResponseSearchResults(results);
        };

        useEffect(() => {
            const searchResults = surveyResponseSearchResults?.map(
                (x: SurveyResponseDto) =>
                    ({
                        value: x?.id,
                        label: moment(x.date).format(MOMENT_WRITTEN_MONTH_DAY_YEAR_FORMAT),
                        checkBox: true,
                    } as SelectCustomOption)
            );

            const merged = mergeSelectedOptionsWithSearchResults(
                searchResults,
                selectedSurveyResponseOptions
            );

            setSurveyReponseOptions(merged);
            if (selectAll)
                setSelectedSurveyResponseIds(
                    surveyResponseSearchResults.map((survey) => survey.id!)
                );
        }, [surveyResponseSearchResults, selectedSurveyResponseOptions]);

        const handleSelectAllClick = () => {
            setSelectAll(true);
        };

        const handleClearClick = () => {
            setSelectedSurveyResponseIds([]);
            setSelectAll(false);
        };

        useEffect(() => {
            if (selectAll) {
                setSelectedSurveyResponseIds(surveyResponseOptions.map((option) => option.value));
            }
        }, [selectAll]);

        useEffect(() => {
            setSubmitButtonEnabled(graphCheckboxState || selectedSurveyResponseIds.length > 0);
        }, [graphCheckboxState, selectedSurveyResponseIds]);

        const handleSelectedSurveyChange = (value: any) => {
            setSelectedSurveyIds(value.value);
            const options = [value as SelectCustomOption];
            setSelectedSurveysOptions(options);

            setSelectedSurveyResponseIds([]);
            setSelectedSurveyResponseOptions([]);
            searchSurveyResponses(value.value);
        };

        const dismiss = (success = false): void => {
            onComplete(success);
            form.resetFields();
        };

        const exit = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: null,
                    title: t('ConfirmModal.confirm_modal_title'),
                    message: t('ConfirmModal.confirm_modal_message'),
                    positiveText: t('yes'),
                    negativeText: t('no'),
                }))
            )
                return;
            dismiss();
        };

        const submit = async (): Promise<void> => {
            try {
                globalLoadingStore.addLoading();

                if (graphCheckboxState) {
                    //create the graph and useEffects will handle submitting when ready
                    const graphData = await fetchGraphData();
                    setGraphData(graphData);
                } else {
                    const request: ExportPatientStatisticsReportRequestDto = {
                        surveyResponseIds: selectedSurveyResponseIds,
                    };

                    const document = await patientService.exportPatientStatisticsReport(
                        patientId,
                        request
                    );
                    showFile(document, t('statistics'));

                    dismiss(true);
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                if (!graphCheckboxState) globalLoadingStore.removeLoading();
            }
        };

        //Called after the base64 of graph is ready
        const submitWithGraph = async (): Promise<void> => {
            try {
                globalLoadingStore.addLoading();

                const request: ExportPatientStatisticsReportRequestDto = {
                    surveyResponseIds: selectedSurveyResponseIds,
                    mpn10LatestTrendChartImage: {
                        uploadBase64: graphBase64,
                    } as UpdateFileRequestDto,
                };

                const document = await patientService.exportPatientStatisticsReport(
                    patientId,
                    request
                );
                showFile(document, t('statistics'));

                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeAllLoading();
            }
        };

        const fetchGraphData = useCallback(async (): Promise<SurveyResponseDto[]> => {
            try {
                let currentPage = 0;
                let fetchedAll = false;
                const result: SurveyResponseDto[] = [];

                while (!fetchedAll) {
                    const request: GetSurveyResponsesRequestDto = {
                        patientIds: [patientId],
                        surveyIds: [MPN10_SURVEY_ID],
                        page: currentPage,
                        pageSize: 50,
                        minDate: getMinDateFilter(DateRangeDropdownOption.Last3Months),
                    };
                    const [response, totalItemCount] =
                        await surveyResponseService.getSurveyResponses(request);
                    result.push(...response);
                    currentPage++;
                    fetchedAll = result.length === totalItemCount;
                }
                return result;
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.serverError();
                }
                return [];
            }
        }, []);

        useEffect(() => {
            if (graphData && graphRef) {
                setTimeout(() => {
                    convertGraphToBase64();
                }, 2500);
            }
        }, [graphData, globalLoadingStore, graphRef]);

        const convertGraphToBase64 = useCallback(async () => {
            if (graphRef.current) {
                const svgelement = (graphRef.current as any).container.firstChild;
                const url = new XMLSerializer().serializeToString(svgelement);
                const b64 = btoa(url);
                setGraphBase64(b64);
            }
        }, []);

        useEffect(() => {
            if (graphBase64) submitWithGraph();
        }, [graphBase64]);

        return (
            <BaseModal
                visible={visible}
                className="PrintStatisticsModal ConfirmationModal"
                width={784}
                onCancel={exit}
                closeIcon={<Close fill={theme.black} />}
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Title level={2} className="title">
                        {t('print_statistics')}
                    </Title>

                    <Row>
                        <Col span={24}>
                            <ValidatedFormItem className="no-x-error" name="surveyId">
                                <Checkbox
                                    checked={graphCheckboxState}
                                    onChange={(e) => setGraphCheckboxState(e.target.checked)}
                                >
                                    {t('mpn_10_latest_trend')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem className="no-x-error" name="surveyId">
                                <SelectCustom
                                    options={surveyOptions}
                                    placeholder={t('select_questionnaire')}
                                    onKeywordsChange={handleSurveyKeywordsChange}
                                    onMenuScrollToBottom={handleSurveysMenuScrollToBottom}
                                    onChange={(value: any): void =>
                                        handleSelectedSurveyChange(value)
                                    }
                                    hideSelectedOptions={false}
                                    selected={selectedSurveyIds}
                                    isLoading={selectLoading}
                                    closeMenuOnSelect={true}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem className="no-x-error" name="surveyResponseIds">
                                <SelectCustom
                                    options={surveyResponseOptions}
                                    onChange={(values: any): void => {
                                        if (values === '') setSelectedSurveyResponseIds([]);
                                        else {
                                            setSelectedSurveyResponseIds(
                                                values?.map((x: any) => x.value)
                                            );
                                            setSelectedSurveyResponseOptions(values);
                                        }
                                        setSelectAll(false);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedSurveyResponseIds}
                                    isLoading={selectLoading}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    multiSelectableAction
                                    onSelectAllClick={handleSelectAllClick}
                                    onClearClick={handleClearClick}
                                    placeholder={t('SelectCustom.default_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="negative secondary"
                            onClick={() => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            type="primary"
                            className="positive"
                            htmlType="submit"
                            disabled={!submitButtonEnabled}
                        >
                            {t('print')}
                        </Button>
                    </div>
                </Form>

                {graphData && graphData.length > 0 && (
                    <div style={{ display: 'none' }}>
                        <Chart
                            height={421}
                            data={graphData}
                            selectedSymptom={SymptomDropdownExtendedMPNSymptomEnum.TotalScore}
                            selectedDateRange={DateRangeDropdownOption.Last3Months}
                            convertToPngRef={graphRef}
                            convertSize={{ width: 820, height: 300 }}
                        />
                    </div>
                )}
            </BaseModal>
        );
    }
);

export default React.memo(PrintStatisticsModal);
