import { useTranslation } from 'react-i18next';

export const genericErrorText = (): string => {
    const { t } = useTranslation();
    return t('Toast.toast_generic_error');
};

export const serverErrorText = (): string => {
    const { t } = useTranslation();
    return t('Toast.toast_server_error');
};
