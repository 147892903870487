import { Modal, Typography } from 'antd';
import { Close } from 'Components/icons';
import { MPNSymptom } from 'Models/Mpn10Question/Mpn10QuestionMap';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './symptom-explanation-modal.less';

const { Title } = Typography;

export interface ConfirmationModalProps {
    title: MPNSymptom;
    onClose: () => void;
    visible: boolean;
}

const SymptomExplanationModal: FunctionComponent<ConfirmationModalProps> = ({
    title,
    onClose,
    visible,
}) => {
    const { t } = useTranslation();
    const description = (): string => {
        switch (title) {
            case MPNSymptom.Fatigue:
                return t('SymptomExplanationModal.fatigue_explanation');
            default:
                return t('SymptomExplanationModal.others_explanation');
        }
    };

    return (
        <Modal
            visible={visible}
            className="SymptomExplanationModal"
            width={780}
            onCancel={onClose}
            closeIcon={<Close fill={theme.black} />}
        >
            <Title level={2} className="title">
                {title}
            </Title>
            <div className="description">{description()}</div>

            <div className="scale-container">
                <div className="scale">
                    <div className="number">{'0'}</div>
                    <div className="text">{t('(absent)')}</div>
                </div>

                <div className="up-to">{t('up_to')}</div>

                <div className="scale">
                    <div className="number">{'10'}</div>
                    <div className="text">{t('(worst_imaginable)')}</div>
                </div>
            </div>
        </Modal>
    );
};

export default SymptomExplanationModal;
