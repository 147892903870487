import { action, computed, observable } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { SurveyService } from 'Services/SurveyService';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import { GetSurveysRequestDto } from 'Api/Features/Surveys/Dtos/GetSurveysRequestDto';
import moment from 'moment';

@inject(SurveyService)
class SurveyStore {
    constructor(private readonly surveyService: SurveyService) {}

    lastCallTime: moment.Moment | undefined = undefined;

    @observable
    private _surveys: SurveyDto[] = [];

    @computed
    get surveys(): SurveyDto[] {
        return this._surveys;
    }

    @action
    async setSurveys(ignoreCaching = false): Promise<void> {
        if (
            ignoreCaching ||
            this.lastCallTime === undefined ||
            moment().diff(this.lastCallTime, 'minutes') > 15
        ) {
            let currentPage = 0;
            let fetchedAllSurveys = false;
            let surveys: SurveyDto[] = [];

            while (!fetchedAllSurveys) {
                const request: GetSurveysRequestDto = {
                    pageSize: 100,
                    page: currentPage,
                };
                const [items, totalItemCount] = await this.surveyService.getSurveys(request);
                surveys = [...surveys, ...items];
                if (surveys.length === totalItemCount) fetchedAllSurveys = true;
                else currentPage++;
            }
            this._surveys = surveys;
        }
    }
}

export default SurveyStore;
