import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Mail: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 16 17" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.21935 3.40965C2.0414 3.40965 1.87074 3.48035 1.74491 3.60618C1.61908 3.73201 1.54839 3.90267 1.54839 4.08062V12.7516C1.54839 12.9295 1.61908 13.1002 1.74491 13.226C1.87074 13.3519 2.0414 13.4226 2.21935 13.4226H13.7806C13.9586 13.4226 14.1293 13.3519 14.2551 13.226C14.3809 13.1002 14.4516 12.9295 14.4516 12.7516V4.08062C14.4516 3.90267 14.3809 3.73201 14.2551 3.60618C14.1293 3.48035 13.9586 3.40965 13.7806 3.40965H2.21935ZM0.650034 2.5113C1.06624 2.09509 1.63075 1.86127 2.21935 1.86127H13.7806C14.3693 1.86127 14.9338 2.09509 15.35 2.5113C15.7662 2.92751 16 3.49201 16 4.08062V12.7516C16 13.3402 15.7662 13.9047 15.35 14.3209C14.9338 14.7371 14.3693 14.9709 13.7806 14.9709H2.21935C1.63075 14.9709 1.06624 14.7371 0.650034 14.3209C0.233824 13.9047 0 13.3402 0 12.7516V4.08062C0 3.49201 0.233824 2.92751 0.650034 2.5113Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.56591 8.01071L0.982605 3.93682L2.01127 2.77952L6.5946 6.85343L6.59462 6.85345C6.98199 7.19778 7.48225 7.38798 8.00053 7.38798C8.51881 7.38798 9.01907 7.19778 9.40643 6.85345L13.989 2.77956L15.0178 3.93678L10.4352 8.01069L10.4351 8.01071C9.76434 8.60699 8.89804 8.93637 8.00053 8.93637C7.10302 8.93637 6.23673 8.607 5.56593 8.01073C5.56593 8.01072 5.56592 8.01071 5.56591 8.01071Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Mail;
