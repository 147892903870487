import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Padlock: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 14 17" fill="none" {...props}>
        <path
          d="M7 2.1C8.3184 2.1 9.4 3.1816 9.4 4.5V6.9H4.6V4.5C4.6 3.1816 5.6816 2.1 7 2.1ZM11 6.9V4.5C11 2.2984 9.2016 0.5 7 0.5C4.7984 0.5 3 2.2984 3 4.5V6.9H2.2C1.77565 6.9 1.36869 7.06857 1.06863 7.36863C0.768569 7.66869 0.599998 8.07565 0.599998 8.5V14.9C0.599998 15.3243 0.768569 15.7313 1.06863 16.0314C1.36869 16.3314 1.77565 16.5 2.2 16.5H11.8C12.2243 16.5 12.6313 16.3314 12.9314 16.0314C13.2314 15.7313 13.4 15.3243 13.4 14.9V8.5C13.4 8.07565 13.2314 7.66869 12.9314 7.36863C12.6313 7.06857 12.2243 6.9 11.8 6.9H11ZM2.2 8.5H11.8V14.9H2.2V8.5Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Padlock;
