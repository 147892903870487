//to enable color referencing in components

export const theme = {
    'primary-color': '#AB3042',
    'primary-900': '#110507',
    'primary-700': '#78212E',
    'primary-600': '#972A3A',
    'primary-500': '#AB3042',
    'primary-300': '#DDACB3',
    'primary-100': '#F7EBED',
    
    'secondary-500': '#5B30AB',
    'secondary-100': '#E1D7F4',
    'tiercery-500': '#30AB99',
    'tiercery-100': '#D7F4F0',
    
    'grayscale-900': '#19191A',
    'grayscale-700': '#3A3A3B',
    'grayscale-500': '#727274',
    'grayscale-300': '#B2B2B3',
    'grayscale-200': '#D8D8D9',
    'grayscale-100': '#E6E7E8',
    'grayscale-050': '#FAFAFA',
    
    'success-color': '##17361C',
    'success-900': '#17361C',
    'success-500': '#4CB25D',
    'success-100': '#E4F0E6',
    
    'error-color': '#D72D2D',
    'error-900': '#4B0F01',
    'error-500': '#D72D2D',
    'error-100': '#F5D6D6',
    
    'warning-color': '#FFD255',
    'warning-900': '#3F2F0D',
    'warning-500': '#FFD255',
    'warning-100': '#FEF4CD',
    
    'alert-900': '#302103',
    'alert-500': '#F49025',
    'alert-100': '#FCDEB6',

    'white': '#ffffff',
    'black': '#000000',
}