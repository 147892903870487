import React, { Children, cloneElement, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useStores } from '../../Hooks';
import { observer } from 'mobx-react';
import NotFoundPage from 'Routes/error/not-found';
import UnauthorizedPage from 'Routes/error/unauthorized';

const ValidatedRoute: FunctionComponent<RouteProps> = observer(({ children, ...routeProps }) => {
    const { authorizationStore } = useStores();


    const updateChildrenWithProps = Children.map(children, (child: any) => {
        return cloneElement(child, {
            routeProps,
        });
    });
    
    return (
        <Route {...routeProps}>
            {authorizationStore.currentRequestForbidden ? (
                <UnauthorizedPage />
            ) : authorizationStore.currentRequestNotFound ? (
                <NotFoundPage />
            ) : (
                updateChildrenWithProps
            )}
        </Route>
    );
});

export default ValidatedRoute;
