import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GraphIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 24 25" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8955 3.36898V21.6661H11.5043V3.36898H12.8955ZM11.1798 2.10712H13.22C13.6259 2.10712 14.0151 2.2168 14.3021 2.41203C14.5891 2.60726 14.7503 2.87206 14.7503 3.14816V21.8869C14.7503 22.163 14.5891 22.4278 14.3021 22.623C14.0151 22.8182 13.6259 22.9279 13.22 22.9279H11.1798C10.7739 22.9279 10.3847 22.8182 10.0977 22.623C9.81075 22.4278 9.64953 22.163 9.64953 21.8869V3.14816C9.64953 2.87206 9.81075 2.60726 10.0977 2.41203C10.3847 2.2168 10.7739 2.10712 11.1798 2.10712ZM6.04598 15.8614V21.666H4.65487V15.8614H6.04598ZM4.33027 14.5996H6.37057C6.77641 14.5996 7.16563 14.7093 7.4526 14.9045C7.73958 15.0997 7.9008 15.3645 7.9008 15.6406V21.8869C7.9008 22.163 7.73958 22.4278 7.4526 22.623C7.16563 22.8182 6.77641 22.9279 6.37057 22.9279H4.33027C3.92443 22.9279 3.53521 22.8182 3.24824 22.623C2.96127 22.4278 2.80005 22.163 2.80005 21.8869V15.6406C2.80005 15.3645 2.96127 15.0997 3.24824 14.9045C3.53521 14.7093 3.92443 14.5996 4.33027 14.5996ZM19.7452 21.6661V11.6973H18.3541V21.6661H19.7452ZM20.0698 10.4354H18.0295C17.6237 10.4354 17.2344 10.5451 16.9475 10.7404C16.6605 10.9356 16.4993 11.2004 16.4993 11.4765V21.8869C16.4993 22.163 16.6605 22.4278 16.9475 22.623C17.2344 22.8182 17.6237 22.9279 18.0295 22.9279H20.0698C20.4756 22.9279 20.8649 22.8182 21.1518 22.623C21.4388 22.4278 21.6 22.163 21.6 21.8869V11.4765C21.6 11.2004 21.4388 10.9356 21.1518 10.7404C20.8649 10.5451 20.4756 10.4354 20.0698 10.4354Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GraphIcon;
