import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Close } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import './base-modal.less';

const BaseModal: FunctionComponent<ModalProps> = ({
  children,
  centered = true,
  footer = null,
  maskClosable = false,
  width = 784,
  closeIcon = <Close fill={theme.white} />,
  ...props
}) => {
  return (
    <Modal
      centered={centered}
      footer={footer}
      maskClosable={maskClosable}
      width={width}
      closeIcon={closeIcon}
      {...props}
    >
      <div className="curved-line">
        <svg viewBox="0 0 500 150">
          <path
            d="M-14.21,-10.36 C-47.00,43.91 470.47,51.80 528.05,-9.38 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: theme["primary-color"] }}
          ></path>
        </svg>
      </div>

      <div className="children">{children}</div>
    </Modal>
  );
};

export default BaseModal;
