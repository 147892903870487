import { AnswerPhysicianRequestsRequestDto } from 'Api/Features/Physicians/Dtos/AnswerPhysicianRequestsRequestDto';
import { CreatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/CreatePhysicianRequestDto';
import { GetPhysiciansRequestDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansRequestDto';
import { GetPhysiciansResponseDto } from 'Api/Features/Physicians/Dtos/GetPhysiciansResponseDto';
import { PhysicianDetailsDto } from 'Api/Features/Physicians/Dtos/PhysicianDetailsDto';
import { PhysicianDto } from 'Api/Features/Physicians/Dtos/PhysicianDto';
import { TransferPhysicianPatientsRequestDto } from 'Api/Features/Physicians/Dtos/TransferPhysicianPatientsRequestDto';
import { UpdatePhysicianRequestDto } from 'Api/Features/Physicians/Dtos/UpdatePhysicianRequestDto';
import { PhysicianProxy } from 'Api/Features/Physicians/PhysicianProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(PhysicianProxy)
export class PhysicianService extends ApiService {
    constructor(private readonly physicianProxy: PhysicianProxy) {
        super();
    }

    public async getPhysician(physicianId: string): Promise<PhysicianDetailsDto | null> {
        const data: PhysicianDto | null = await this.physicianProxy.getPhysician(physicianId);
        return data;
    }
    public async getPhysicians(
        request: GetPhysiciansRequestDto | null
    ): Promise<[PhysicianDto[], number]> {
        const data: GetPhysiciansResponseDto | null = await this.physicianProxy.getPhysicians(
            request
        );
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createPhysician(request: CreatePhysicianRequestDto | null): Promise<string> {
        const data: string = await this.physicianProxy.createPhysician(request);
        return data;
    }

    public async updatePhysician(
        physicianId: string,
        request: UpdatePhysicianRequestDto | null
    ): Promise<void> {
        await this.physicianProxy.updatePhysician(physicianId, request);
    }

    public async deletePhysician(physicianId: string): Promise<void> {
        await this.physicianProxy.deletePhysician(physicianId);
    }

    public async transferPhysicianPatients(physicianId: string, request: TransferPhysicianPatientsRequestDto | null): Promise<void> {
        await this.physicianProxy.transferPhysicianPatients(physicianId, request);
    }

    public async answerPhysicianRequests(physicianId: string, request: AnswerPhysicianRequestsRequestDto | null): Promise<void> {
        await this.physicianProxy.answerPhysicianRequests(physicianId, request);
    }
}
