import React, { FunctionComponent } from 'react';
import MPNLogo from 'Assets/Images/logo-white.svg';
import MPNLogoRed from 'Assets/Images/logo-red.svg';
import { theme } from 'Style/theme';
import './policies.less';
import moment from 'moment';
import { MOMENT_YEAR_FORMAT } from 'Models/Constants';

const PrivacyPolicy: FunctionComponent = () => {
    return (
        <div className="PrivacyPolicy">
            <div className="header-container">
                <div className="logo-container">
                    <img className="logo" src={MPNLogo} alt="logo" />
                </div>

                <div className="curved-line">
                    <svg viewBox="0 0 500 150">
                        <path
                            d="M-14.21,-10.36 C-47.00,43.91 470.47,51.80 528.05,-9.38 L500.00,0.00 L0.00,0.00 Z"
                            style={{ stroke: 'none', fill: theme['primary-color'] }}
                        ></path>
                    </svg>
                </div>
            </div>

            <div className="box-container">
                <div className="box Shadow-MPN">
                    <div className="H2-bold">MPN Genie Privacy policy</div>
                    <div className="Paragraph">
                        The Canadian MPN Research Foundation (“CMPNRF”) recognizes the importance of
                        protecting the personal information including personal health information
                        that it receives and manages is and the privacy of the individuals to whom
                        it relates. CMPNRF values the trust placed in us by individuals who provide
                        or otherwise permit us to collect and use their personal information and
                        recognizes that maintaining this trust requires us to be transparent and
                        accountable for how we treat personal information.
                    </div>
                    <div className="Paragraph">
                        This privacy policy describes the personal information that CMPNRF collects
                        electronically through its application, MPNGenie also referred to in this
                        policy as the “APP” and how we manage that information. This policy does not
                        apply to any other collection, use or disclosure of personal information by
                        the CMPNRF or any third party, including by individuals and health care
                        providers, as described below.
                    </div>
                    <div className="Paragraph">
                        Individuals registered to use MPNGenie (“participants”) may permit their
                        health care provider (“provider”) access to their MPNGenie account which
                        gives the provider access to the personal information in the participants
                        account, the access permits providers to use MPNGenie to collect personal
                        health information “PHI” from and disclosure “PHI” to their
                        participant/patient. By consenting to their provider having access to their
                        MPNGenie account, participants are consenting to the CMPNRF using their
                        personal information to send an alert by email to their provider when they
                        add information to their MPN genie account. CMPNRF makes MPNGenie available
                        for this purpose as a service to participants and their providers pursuant
                        to the terms and conditions to which all users of the app - participants and
                        providers- must comply. Participants considering permitting their provider
                        access to their MPNGenie account need to consider the following. CMPRNF is
                        not responsible for the collection use or disclosure of their “PHI” by and
                        between participants and their provider through the app. Providers are
                        responsible for the management of “PHI” that they collect and disclose
                        through MPNGenie. The law governing such collection and disclosure and the
                        use of “PHI” by a provider, is the law applicable to the provider.
                        Participants are not required to permit access to their MPNGenie account to
                        their provider and do so at their own risk. Participants must direct any
                        complaint or concern about the providers collection or use or disclosure of
                        “PHI” through the app to the appropriate regulatory, such as the providers
                        professional college and or the information and privacy commissioner of
                        Ontario. CMPNRF will not intervene in nor adjudicate any dispute between a
                        participant and his or her provider. CMPNRF will terminate the access of a
                        provider on written direction from a participant, but such termination does
                        not affect “PHI” collected and disclosed up to the date of termination.
                    </div>
                    <div className="Paragraph">
                        CMPNRF may amend this policy from time-to-time period CMPRF will post the
                        date of any amendments above. CMPNRF will require you to confirm whether
                        this policy has been amended prior to entering personal information into
                        MPNGenie as by doing so, you will be understood to be consenting to the
                        collection, use and retention of the information in accordance with the
                        amended policy. In this policy “personal information” is information about
                        an identifiable individual. This includes information such as name, age,
                        gender, health indicators and status, personal email address and year of
                        birth.
                    </div>

                    <div className="H2-bold">2. MPN Genie</div>
                    <div className="Paragraph">
                        MPNGenie is a mobile application through which individuals living with an
                        MPN blood cancer (MPN) who choose to become participants provide historical
                        and current information related to their diagnosis, symptoms, treatment
                        decisions, quality of life, and daily activities to CMPNRF or to the CMPNRF
                        and their provider. Participants disclose their information to CMPNRF and
                        providers by entering into the app. Individuals must register to use
                        MPNGenie and agree to its terms and conditions of use. CMPNRF uses personal
                        information provided by participants for the purposes described in Section 4
                        below, which include research into MPN blood cancers. Providers are
                        restricted to collecting information through MPNGenie for the purpose of
                        providing healthcare to participants, but as above, once collected, the
                        personal information is in the custody of the provider and its use within
                        the providers’ control.
                    </div>

                    <div className="H2-bold">
                        3. What information does CMPNRF collect from participants?
                    </div>
                    <div className="Paragraph">
                        A. CMPNRF will collect the following information from participants, one time
                        only upon registration for a MPNGenie account: their email address; Their
                        birthdate; Gender; Weight and height; Highest completed level of schooling;
                        A province or territory of residence. CMPNRF will also collect baseline
                        quality of life scores and baseline health status scores the first time the
                        participant completes the questionnaire and an ongoing basis. These
                        questionnaires are also sent every week and are listed in section C of this
                        policy. Providers given access to a participant&apos;s account may update
                        his or her scores.
                    </div>
                    <div className="Paragraph">
                        B. CMPNRF may collect the following clinical and health care information
                        from participants every six months:
                        <ul>
                            <li>
                                their most recent MPN diagnosis and the year in which they were
                                diagnosed with an MPN blood, cancer their current and previous
                                experiences with symptom flare ups and quality of life changes their
                                use of cigarettes tobacco and cannabis products information
                                regarding the different types of health care providers which are
                                currently involved in their treatment of MPN blood cancers.
                                Participants are discouraged from disclosing the names of their
                                health care providers to CMPNRF unless for the purpose of permitting
                                a provider access to the participants MPNGenie account information
                                regarding their history of symptom onset and diagnosis of MPN
                                patterns and severity of symptoms and any time spent in hospital
                                information regarding their involvement in treatment decisions and
                                information regarding their current and historical medication
                                prescription and usage
                            </li>
                        </ul>
                    </div>
                    <div className="Paragraph">
                        C. CMPNRF will collect the following information about outcomes from
                        participants every week:
                        <ul>
                            <li>
                                the nature of their current treatment, their experiences with
                                controlling and their MPN blood cancer and quality of life symptoms
                                and the usefulness of their current treatment their experience with
                                psychological and psychosocial symptoms and side effects their
                                current needs and their plans for future changes in treatment their
                                health status scores based on the MPN 10 prognostic scoring
                                indicators, their utilization of hospital emergency department and
                                or hospitalization events and their quality of life scores based on
                                the MPN 10 prognostic scoring indicator.
                            </li>
                        </ul>
                    </div>
                    <div className="Paragraph">
                        D. the following information will be collected as often as participants wish
                        to provide it: information regarding their weekly schedules and activities
                        such as sleeping eating physical exercise and other quality of life issues.
                    </div>

                    <div className="H2-bold">
                        4. How does CMPNRF protect personal information it collects through
                        MPNGenie?
                    </div>
                    <div className="Paragraph">
                        CMPNRF employs safeguards to protect the information that participants
                        provide. We have security measures and tools in place to help protect
                        against the theft, loss, unauthorized collection, use and disclosure an
                        alteration of the information held in MPNGenie. Technical access controls
                        are designed to allow only authorized individuals to access the systems that
                        hold this information, and we train our employees to maintain the
                        confidentiality of this information and privacy of the individuals to whom
                        are entitled. CMPNRF also protects participant privacy by limiting the
                        information that we collect and by removing their information from CMPNRF
                        database when a participant withdrawals from MPNGenie as described in
                        Section 7 below. Participant information is also stored in Canada and
                        protected by Microsoft azure&apos;s privacy policy and terms of use. As well
                        as apple and android&apos;s privacy policy and terms of use. Notwithstanding
                        the protective measures CMPNRF has in place no method of transmitting or
                        storing information in electronic form is completely secure. As a result,
                        CMPNRF cannot guarantee the security of any information participants provide
                        to us through or in connection with MPNGenie. In the unfortunate event of a
                        data breach, we will inform the affected participants. CMPNRF may provide
                        such in notification by email.
                    </div>

                    <div className="H2-bold">
                        5. How do participants access or change their personal information stored in
                        MPNGenie?
                    </div>
                    <div className="Paragraph">
                        Participants may change the information in their profile in MPNGenie at
                        anytime. Upon written, verifiable request, CMPNRF will inform individuals of
                        whether it has their personal information in its records.
                    </div>

                    <div className="H2-bold">
                        6. How do participants withdraw from participating in MPNGenie?
                    </div>
                    <div className="Paragraph">
                        Participants may remove their personal information from MPNGenie if they no
                        longer wish to use the app. Participants erase their information from
                        MPNGenie by deleting their account. This functionality is available in the
                        account settings menu. When a participant deletes his/her account, CMPNRF
                        removes his/ her personal information from the CMPNRF active user databases,
                        but the information may stay in a backup storage for up to 30 days before it
                        is completely removed or destroyed. Any information collected prior to
                        withdrawal from MPNGenie may still be used by CMPNRF in deidentified or
                        aggregate form for the purpose described in this policy. Since the
                        information is no longer linked to a participant CMPNRF cannot extract it
                        from it&apos;s deidentified or aggregate information holdings.
                    </div>

                    <div className="H2-bold">Disclaimer</div>
                    <div className="Paragraph">
                        MPNGenie and content are provided as is and as available, without any
                        representation, warranty, conditions or guarantees of any kind. To the
                        maximum extent permitted by law, we, and our affiliates, including each of
                        their respective directors, officers, employees, agents, service providers,
                        license orders, successors, and assigns “CMPNRF entity”, disclaim all
                        representations, warranties, conditions or guarantees, express or implied,
                        including but not limited to, implied warranties and conditions of
                        Merchantability, fitness for a particular purpose, title and
                        noninfringement. The CMPNRF entity does not warrant that MPNGenie including
                        content will be accurate, secured, uninterrupted or error free; that defect
                        will be corrected; That MPNGenie or the server that make MPNGenie and
                        content available are free of harmful components or that MPNGenie or content
                        will meet your requirements. You are solely responsible for any damage to
                        your equipment or device, loss of use or loss of data. for clarity, the
                        CMPNRF entity does not make any guarantee of the quality accuracy, or
                        reasonableness of any content including without limitation user data. The
                        foregoing does not affect any warranties that cannot be excluded or limited
                        under applicable law. The CMPNRF is not responsible for any errors or
                        omissions or injury, loss, or damage arising from or relating to the use or
                        misuse of content or information applied by content on or available through
                        MPNGenie in any of these source documentations. Content, other than user
                        data, is provided for general information purposes only. It is not advice on
                        which you can rely. You must obtain professional advice before taking or
                        refraining from taking any action on the basis of content as it may not
                        apply to you or maybe missing material information. From time to time, we
                        may perform maintenance on MPNGenie during this time, access to MPNGenie and
                        particular features, or content including user data may be unavailable or
                        limited.
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="copyright">{`© ${moment().format(
                    MOMENT_YEAR_FORMAT
                )} MPNGENIE Inc. All rights reserved`}</div>
                <img className="logo" src={MPNLogoRed} alt="logo" />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
