import React, { FunctionComponent, useState } from 'react';
import { Button, Row, Col, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { Padlock } from 'Components/icons';
import { theme } from 'Style/theme';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UserService } from 'Services/UserService';
import { SetPasswordSchema } from 'Schemas/ResetPasswordSchema';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import './set-password.less';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SetPassword: FunctionComponent = observer(() => {
    const { t, i18n } = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [errors, validateForm] = useFormValidation(SetPasswordSchema);
    const userService = useService(UserService);
    const { toastStore } = useStores();
    const history = useHistory();
    const [spinner, setSpinner] = useState(false);
    const [form] = Form.useForm();
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const requestIsPatient = urlParams.get('is-patient');

    const getTermsOfUseUrl = () => {
        let lang;
        switch (i18n.language) {
            case 'en':
                lang = 'EN';
                break;
            case 'fr':
                lang = 'FR';
                break;
            case 'de':
                lang = 'DE';
                break;
            case 'es':
                lang = 'ES';
                break;
            case 'it':
                lang = 'IT';
                break;
            default:
                lang = 'EN';
        }
        return `/Terms_Of_Use_${lang}`;
    };

    const getPrivacyPolicyUrl = () => {
        let lang;
        switch (i18n.language) {
            case 'en':
                lang = 'EN';
                break;
            case 'fr':
                lang = 'FR';
                break;
            case 'de':
                lang = 'DE';
                break;
            case 'es':
                lang = 'ES';
                break;
            case 'it':
                lang = 'IT';
                break;
            default:
                lang = 'EN';
        }
        return `/Privacy_policy_${lang}`;
    };

    async function submit(): Promise<void> {
        const formValues = form.getFieldsValue();
        const validateModel = {
            ...formValues,
            isPatient: requestIsPatient === 'True',
            acceptedTerms,
        };

        if (!(await validateForm(validateModel))) return;

        const request: UpdateUserPasswordAnonymousRequestDto = {
            newPassword: formValues.newPassword,
            currentPassword: null,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
        };

        try {
            setSpinner(true);
            await userService.updateUserPasswordAnonymous(request);
            if (validateModel.isPatient) {
                history.push('/download-app');
            } else {
                history.push('/console-password-confirmed');
            }
        } catch (error: any) {
            if (!error.treated) {
                toastStore.genericError();
            }
        } finally {
            setSpinner(false);
        }
    }

    return (
        <div className="SetPassword">
            <Row className="heading">
                <Col className="H2-bold">{t('set_password')}</Col>
            </Row>

            <Row className="heading">
                <Col className="Paragraph">{t('SetPassword.set_password_for_account')}</Col>
            </Row>

            <Row>
                <Col span={16} offset={4}>
                    <Form layout="vertical" onFinish={submit} form={form}>
                        <ValidatedFormItem
                            errors={errors}
                            label={t('Login.password')}
                            name="newPassword"
                            className="password-input"
                        >
                            <Input
                                prefix={<Padlock fill={theme['primary-color']} />}
                                type="password"
                                maxLength={50}
                            />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            label={t('confirm_password')}
                            name="confirm"
                        >
                            <Input
                                prefix={<Padlock fill={theme['primary-color']} />}
                                type="password"
                                maxLength={50}
                            />
                        </ValidatedFormItem>

                        {requestIsPatient === 'True' && (
                            <ValidatedFormItem errors={errors} name="acceptedTerms">
                                <Checkbox
                                    value={acceptedTerms}
                                    onChange={(event) => setAcceptedTerms(event.target.checked)}
                                >
                                    <span className="Paragraph">
                                        {t('SetPassword.i_accept_the')}{' '}
                                        <a
                                            href={getTermsOfUseUrl()}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                        >
                                            {t('SetPassword.terms_of_use')}
                                        </a>{' '}
                                        {t('SetPassword.and')}{' '}
                                        <a
                                            href={getPrivacyPolicyUrl()}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                        >
                                            {t('SetPassword.privacy_policy')}
                                        </a>
                                    </span>
                                </Checkbox>
                            </ValidatedFormItem>
                        )}

                        {spinner && (
                            <div className="spinner">
                                <Spin indicator={antIcon} size="large" />
                            </div>
                        )}

                        <Button type="primary" htmlType="submit" className="set-btn">
                            {t('set_password')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
});

export default SetPassword;
