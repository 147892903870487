import React, { FunctionComponent } from 'react';
import { Row, Col, Button } from 'antd';
import { observer } from 'mobx-react';
import './console-password-confirmed.less';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConsolePasswordConfirmed: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className="ConsolePasswordConfirmed">
            <Row className="heading">
                <Col className="H2-bold">{t('ConsolePasswordConfirmed.its_updated')}</Col>
            </Row>

            <Row className="heading">
                <Col className="Paragraph">{t('ConsolePasswordConfirmed.password_updated')}</Col>
            </Row>

            <Button className="login-btn" type="primary" onClick={() => history.push('/')}>
                {t('log_in')}
            </Button>
        </div>
    );
});

export default ConsolePasswordConfirmed;
