import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PlusSign: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99998 0.333344C9.53181 0.333344 9.96294 0.764477 9.96294 1.29631V8.03705H16.7037C17.2355 8.03705 17.6666 8.46818 17.6666 9.00001C17.6666 9.53184 17.2355 9.96297 16.7037 9.96297H9.96294V16.7037C9.96294 17.2355 9.53181 17.6667 8.99998 17.6667C8.46815 17.6667 8.03702 17.2355 8.03702 16.7037V9.96297H1.29628C0.764446 9.96297 0.333313 9.53184 0.333313 9.00001C0.333313 8.46818 0.764446 8.03705 1.29628 8.03705H8.03702V1.29631C8.03702 0.764477 8.46815 0.333344 8.99998 0.333344Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PlusSign;
