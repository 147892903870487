import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import './table-filters.less';
import FilterStore from '../../Stores/FilterStore';
import { Col, Input, Row } from 'antd';
import { theme } from 'Style/theme';
import { Search } from 'Components/icons';
import { TreatmentTypeDto } from 'Api/Features/Patients/Dtos/TreatmentTypeDto';

import { ALL_ROLES, ALL_TREATMENTS } from 'Models/Constants';
import { TimeframeFilterEnum } from 'Filters/TimeframeFilter';
import Select, { SelectOption } from 'Components/select/select';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { TreatmentTypeToString } from 'Models/TreatmentType/TreatmentTypeToString';
import { useTranslation } from 'react-i18next';

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;
    includeTreatmentType?: boolean;
    includeTimeframe?: boolean;
    includeRoles?: boolean;
}

const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({ filterStore, includeSearch, includeTreatmentType, includeTimeframe, includeRoles }) => {
        const { t } = useTranslation();

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        //#region change events
        const onTreatmentTypeChange = (treatment: TreatmentTypeDto): void => {
            filterStore.updateTreatmentType(treatment);
        };

        const onTimeframeChange = (timeframe: TimeframeFilterEnum): void => {
            filterStore.updateTimeframe(timeframe);
        };

        const onRoleChange = (role): void => {
            filterStore.updateRole(role);
        };
        //#end region change events

        //#region options
        const treatmentTypeOptions = (): SelectOption[] => {
            const options = Object.keys(TreatmentTypeDto).map((type) => ({
                id: type,
                name: TreatmentTypeToString(TreatmentTypeDto[type], t),
            }));
            options.unshift({
                id: ALL_TREATMENTS,
                name: t('all_treatments'),
            });
            return options;
        };

        const timeframeOptions = (): SelectOption[] => {
            const options = Object.keys(TimeframeFilterEnum).map((timeframe) => ({
                id: timeframe,
                name: t(`TimeframeFilterEnum.TimeframeFilterEnum_${timeframe}`),
            }));
            return options;
        };

        const roleOptions = (): SelectOption[] => {
            const options = Object.keys(ManagementRoleDto)
                .filter((role) => role !== ManagementRoleDto.Physician)
                .map((role) => ({
                    id: role,
                    name: t(`ManagementRoleDto.ManagementRoleDto_${role}`),
                }));
            options.unshift({
                id: ALL_ROLES,
                name: t('all_roles'),
            });
            return options;
        };
        //#end region options

        return (
            <div className="TableFilters">
                <Row>
                    {includeTimeframe && (
                        <Col span={5}>
                            <Select
                                onChange={onTimeframeChange}
                                options={timeframeOptions()}
                                value={filterStore.timeframe}
                            />
                        </Col>
                    )}

                    {includeTreatmentType && (
                        <Col span={5}>
                            <Select
                                onChange={onTreatmentTypeChange}
                                options={treatmentTypeOptions()}
                                value={filterStore.treatmentType}
                            />
                        </Col>
                    )}

                    {includeRoles && (
                        <Col span={5}>
                            <Select
                                onChange={onRoleChange}
                                options={roleOptions()}
                                value={filterStore.role}
                            />
                        </Col>
                    )}

                    <Col flex="auto" />

                    {includeSearch && (
                        <Col span={6}>
                            <Input
                                onChange={onSearchChange}
                                prefix={<Search fill={theme['primary-color']} />}
                                value={filterStore.searchTerm}
                                placeholder={t('search')}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
);

export default TableFilters;
