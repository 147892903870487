import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const ChevronLeft: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 9 16" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47757 15.1214C8.01716 15.5818 7.2707 15.5818 6.81029 15.1214L0.522572 8.83365C0.301476 8.61255 0.177266 8.31268 0.177266 8.00001C0.177266 7.68733 0.301476 7.38746 0.522572 7.16637L6.81029 0.878649C7.2707 0.41824 8.01716 0.41824 8.47757 0.878649C8.93798 1.33906 8.93798 2.08552 8.47757 2.54593L3.0235 8.00001L8.47757 13.4541C8.93798 13.9145 8.93798 14.661 8.47757 15.1214Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default ChevronLeft;
