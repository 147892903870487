import { Button, Col, Form, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import Title from 'antd/lib/typography/Title';
import { PhysicianDto } from 'Api/Features/Physicians/Dtos/PhysicianDto';
import { TransferPhysicianPatientsRequestDto } from 'Api/Features/Physicians/Dtos/TransferPhysicianPatientsRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Close, User } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TransferPatientSchema } from 'Schemas/TransferPatientSchema';
import { PhysicianService } from 'Services/PhysicianService';
import { theme } from 'Style/theme';

interface TransferPatientModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    currentDoctorId?: string | null;
    transferAllPatients?: boolean;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const TransferPatientModal: FunctionComponent<TransferPatientModalProps> = observer(
    ({ visible, onComplete, currentDoctorId, transferAllPatients }) => {
        const { t } = useTranslation();
        const [errors, validateForm, resetErrors] = useFormValidation(TransferPatientSchema);
        const [form] = Form.useForm();
        const physicianService = useService(PhysicianService);
        const { confirmationModalStore, globalLoadingStore, toastStore, userStore } = useStores();
        const { id } = useParams<{ id: string }>();

        const pageSize = 25;
        const [doctorsCurrentPage, setDoctorsCurrentPage] = useState(0);
        const [doctorsSearchResults, setDoctorsSearchResults] = useState<PhysicianDto[]>([]);
        const [doctorsSearchTerm, setDoctorsSearchTerm] = useState('');
        const [doctorsMaxResults, setDoctorsMaxResults] = useState(false);
        const [doctorOptions, setDoctorsOptions] = useState<SelectCustomOption[]>([]);
        const [selectedDoctorOptions, setSelectedDoctorOptions] = useState<SelectCustomOption[]>(
            []
        );
        const [selectedDoctorId, setSelectedDoctorId] = useState<string>();
        const [selectLoading, setSelectLoading] = useState(false);

        const resetDoctorsSearch = (): void => {
            setDoctorsCurrentPage(0);
            setDoctorsSearchResults([]);
            setDoctorsSearchTerm('');
            setDoctorsMaxResults(false);
        };

        const handleDoctorKeywordsChange = useCallback((value: string): void => {
            resetDoctorsSearch();
            setDoctorsSearchTerm(value);
        }, []);

        const searchDoctors = async (page: number, searchTerm: string): Promise<PhysicianDto[]> => {
            const args = {
                pageSize: pageSize,
                page: page,
                searchTerm: searchTerm,
            };
            const [results, totalItemCount] = await physicianService.getPhysicians(args);

            if (results.length + pageSize * page >= totalItemCount) {
                setDoctorsMaxResults(true);
            }
            setSelectLoading(false);
            return results;
        };

        const debounceDoctorSearch = useRef(
            debounce((page: number, searchTerm: string) => {
                searchDoctors(page, searchTerm).then((results) => {
                    setDoctorsSearchResults((prevResults) => [...prevResults, ...results]);
                });
            }, 300)
        );

        const handleDoctorsMenuScrollToBottom = (): void => {
            if (!doctorsMaxResults) {
                setDoctorsCurrentPage((prevPage) => prevPage + 1);
            }
        };

        useEffect(() => {
            const searchResults = doctorsSearchResults
                ?.filter((doctor) => doctor.id !== currentDoctorId)
                .map(
                    (x: PhysicianDto) =>
                        ({
                            value: x?.id,
                            label: `${x?.firstName ?? ''} ${x.lastName ?? ''}`,
                            imageUrl: x?.imageUrl,
                            badge: undefined,
                        } as SelectCustomOption)
                );

            const merged = mergeSelectedOptionsWithSearchResults(
                searchResults,
                selectedDoctorOptions
            );

            setDoctorsOptions(merged);
        }, [doctorsSearchResults, selectedDoctorOptions]);

        useEffect(() => {
            setSelectLoading(true);
            debounceDoctorSearch.current(doctorsCurrentPage, doctorsSearchTerm);
        }, [doctorsCurrentPage, doctorsSearchTerm]);

        const dismiss = (success = false): void => {
            onComplete(success);
            form.resetFields();
            resetErrors();
        };

        const exit = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: null,
                    title: t('ConfirmModal.confirm_modal_title'),
                    message: t('ConfirmModal.confirm_modal_message'),
                    positiveText: t('yes'),
                    negativeText: t('no'),
                }))
            )
                return;
            dismiss();
        };

        const submit = async (): Promise<void> => {
            if (!userStore.userInfo?.id) {
                return;
            } else {
                try {
                    globalLoadingStore.addLoading();
                    const request: TransferPhysicianPatientsRequestDto = {
                        newPhysicianId: selectedDoctorId,
                        patientIds: transferAllPatients ? undefined : [id],
                        transferAll: transferAllPatients,
                    };

                    if (!(await validateForm(request))) return;

                    await physicianService.transferPhysicianPatients(
                        userStore.userInfo.id,
                        request
                    );

                    toastStore.toast({
                        type: 'success',
                        messageKey: t('Doctor.transfer_patients_success'),
                    });
                    dismiss(true);
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                } finally {
                    globalLoadingStore.removeLoading();
                }
            }
        };

        return (
            <BaseModal
                visible={visible}
                className="TransferPatientModal ConfirmationModal"
                width={784}
                onCancel={exit}
                closeIcon={<Close fill={theme.black} />}
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Title level={2} className="title">
                        {t('Doctor.transfer_patients')}
                    </Title>
                    <div className="message">{t('Doctor.assign_patients')}</div>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                className="no-x-error"
                                errors={errors}
                                name="newPhysicianId"
                            >
                                <SelectCustom
                                    options={doctorOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('Doctor.choose_doctor')}
                                    onKeywordsChange={handleDoctorKeywordsChange}
                                    onMenuScrollToBottom={handleDoctorsMenuScrollToBottom}
                                    onChange={(value: any): void => {
                                        setSelectedDoctorId(value.value);
                                        const options = [value as SelectCustomOption];
                                        setSelectedDoctorOptions(options);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedDoctorId ? [selectedDoctorId] : []}
                                    isLoading={selectLoading}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="negative secondary"
                            onClick={() => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Doctor.transfer_now')}
                        </Button>
                    </div>
                </Form>
            </BaseModal>
        );
    }
);

export default React.memo(TransferPatientModal);
