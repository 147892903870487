import React, { FunctionComponent, MutableRefObject } from 'react';
import { observer } from 'mobx-react';
import {
    CartesianGrid,
    XAxis,
    YAxis,
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { theme } from 'Style/theme';
import DonutChart from 'Components/donut-chart/donut-chart';
import './chart.less';
import {
    MOMENT_DAY_MONTH_FORMAT,
    MOMENT_DAY_MONTH_YEAR_STRING_FORMAT,
    MOMENT_SHORT_MONTH_DISPLAY_FORMAT,
} from 'Models/Constants';
import { useCallback } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import {
    Mpn10StringQuestionMap,
    SymptomDropdownExtendedMPNSymptomEnum,
} from 'Models/Mpn10Question/Mpn10QuestionMap';
import { DateRangeDropdownOption } from 'Components/date-range-dropdown';
import { SurveyResponseUserDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseUserDto';
import { DiagnosisDto } from 'Api/Features/Patients/Dtos/DiagnosisDto';
import { QuestionResponseDto } from 'Api/Features/SurveyResponses/Dtos/QuestionResponseDto';

interface ChartProps {
    height?: number;
    data?: SurveyResponseDto[];
    selectedSymptom: SymptomDropdownExtendedMPNSymptomEnum;
    selectedDateRange: DateRangeDropdownOption;
    convertToPngRef?: MutableRefObject<any>;
    convertSize?: {width: number, height: number}
}

interface ChartData {
    name: string; //Will be the x axis label
    value: number;
    date: string; //date displayed in the tooltip
}

interface SurveyResponseDtoWithMomentDate {
    id?: string;
    surveyId?: string;
    user?: SurveyResponseUserDto | null;
    startDate: moment.Moment;
    submissionDate?: string;
    dateDeleted?: string | null;
    score?: number;
    diagnosis?: DiagnosisDto | null;
    questionResponses?: (QuestionResponseDto | null)[] | null;
}

interface SurveyCluster {
    lowDateBracket: moment.Moment;
    highDateBracket: moment.Moment;
    surveys: SurveyResponseDtoWithMomentDate[];
}

//https://recharts.org/en-US/guide

const Chart: FunctionComponent<ChartProps> = observer(
    ({ height, data, selectedSymptom, selectedDateRange, convertToPngRef, convertSize }) => {
        const getClusterValue = (
            cluster: SurveyCluster,
            selectedSymptomId: string | undefined
        ): number => {
            return Math.round(
                parseFloat(
                    (
                        cluster.surveys
                            .flatMap((survey) =>
                                survey.questionResponses
                                    ?.filter(
                                        (response) =>
                                            selectedSymptomId === undefined ||
                                            response?.questionId === selectedSymptomId
                                    )
                                    .map((question) => question?.numericValue ?? 0)
                            )
                            .filter((score) => score !== undefined)
                            .map((score) => score!)
                            .reduce((a, b) => a + b, 0) / cluster.surveys.length ?? 1
                    ).toFixed(2)
                )
            );
        };

        const orderedBySurveyDateData = (
            data: SurveyResponseDto[]
        ): SurveyResponseDtoWithMomentDate[] => {
            if (data && data?.length > 0) {
                const orderedByDate = data
                    .map((survey) => ({ ...survey, startDate: moment(survey.date) }))
                    .sort((a, b) => (a.startDate as any) - (b.startDate as any));
                return orderedByDate;
            } else {
                return [];
            }
        };

        //3 months => weekly sunday to saturday
        //6 month => weekly sunday to saturday
        //year=> monthly
        //else=> no cluster
        const clusteredDataForSelectedDateRangeAndSymptom = useCallback((): ChartData[] => {
            if (data && data.length > 0) {
                const orderedByDateSurveys: SurveyResponseDtoWithMomentDate[] =
                    orderedBySurveyDateData(data);

                const selectedSymptomId: string | undefined =
                    selectedSymptom !== SymptomDropdownExtendedMPNSymptomEnum.TotalScore
                        ? Mpn10StringQuestionMap[selectedSymptom]
                        : undefined;

                let lowDateBracket = orderedByDateSurveys[0].startDate?.clone().utc();
                let highDateBracket = orderedByDateSurveys[0].startDate?.clone().utc();

                switch (selectedDateRange) {
                    case DateRangeDropdownOption.Last3Months:
                    case DateRangeDropdownOption.Last6Months: {
                        const groupedByWeek: SurveyCluster[] = [];

                        orderedByDateSurveys.map((survey) => {
                            //if survey is in range of the current brackets, add it
                            if (
                                groupedByWeek[groupedByWeek.length - 1]?.lowDateBracket &&
                                survey.startDate?.isBetween(lowDateBracket, highDateBracket)
                            ) {
                                groupedByWeek[groupedByWeek.length - 1].surveys.push(survey);
                            }
                            //different date than current bracket or no cluster yet. Create a new cluster and add survey in cluster
                            else {
                                lowDateBracket = survey.startDate?.clone().utc().startOf('week');
                                highDateBracket = survey.startDate?.clone().utc().endOf('week');
                                groupedByWeek[groupedByWeek.length] = {
                                    lowDateBracket,
                                    highDateBracket,
                                    surveys: [survey],
                                };
                            }
                        });
                        return groupedByWeek.map((cluster) => ({
                            date: `${moment(cluster.lowDateBracket).format(
                                MOMENT_DAY_MONTH_YEAR_STRING_FORMAT
                            )} - ${moment(cluster.highDateBracket).format(
                                MOMENT_DAY_MONTH_YEAR_STRING_FORMAT
                            )}`,
                            name: moment(cluster.lowDateBracket)
                                .utc()
                                .format(MOMENT_DAY_MONTH_FORMAT),
                            value: getClusterValue(cluster, selectedSymptomId),
                        }));
                    }
                    case DateRangeDropdownOption.LastYear: {
                        const groupedByMonth: SurveyCluster[] = [];

                        orderedByDateSurveys.map((survey) => {
                            //if survey is in range of the current brackets, add it
                            if (
                                groupedByMonth[groupedByMonth.length - 1]?.lowDateBracket &&
                                survey.startDate?.isBetween(lowDateBracket, highDateBracket)
                            ) {
                                groupedByMonth[groupedByMonth.length - 1].surveys.push(survey);
                            }
                            //date not in range or no cluster yet. Create a new cluster and add survey in cluster
                            else {
                                lowDateBracket = survey.startDate?.clone().utc().startOf('month');
                                highDateBracket = survey.startDate?.clone().utc().endOf('month');
                                groupedByMonth[groupedByMonth.length] = {
                                    lowDateBracket,
                                    highDateBracket,
                                    surveys: [survey],
                                };
                            }
                        });

                        return groupedByMonth.map((cluster) => ({
                            date: `${moment(cluster.lowDateBracket).format(
                                MOMENT_DAY_MONTH_YEAR_STRING_FORMAT
                            )} - ${moment(cluster.highDateBracket).format(
                                MOMENT_DAY_MONTH_YEAR_STRING_FORMAT
                            )}`,
                            name: moment(cluster.lowDateBracket)
                                .utc()
                                .format(MOMENT_SHORT_MONTH_DISPLAY_FORMAT),
                            value: getClusterValue(cluster, selectedSymptomId),
                        }));
                    }
                    case DateRangeDropdownOption.CurrentMonth:
                    case DateRangeDropdownOption.LastMonth:
                    default: {
                        return orderedByDateSurveys.map(
                            (survey) =>
                                ({
                                    name: moment(survey.startDate)
                                        .utc()
                                        .format(MOMENT_DAY_MONTH_FORMAT),
                                    date: moment(survey.startDate)
                                        .utc()
                                        .format(MOMENT_DAY_MONTH_YEAR_STRING_FORMAT),
                                    value:
                                        selectedSymptom ===
                                        SymptomDropdownExtendedMPNSymptomEnum.TotalScore
                                            ? survey.score
                                            : survey.questionResponses?.find(
                                                  (response) =>
                                                      response?.questionId === selectedSymptomId
                                              )?.numericValue ?? 0,
                                } as ChartData)
                        );
                    }
                }
            } else return [];
        }, [selectedDateRange, selectedSymptom, data]);

        return (
            <ResponsiveContainer
                className="Chart"
                width={convertSize?.width ?? '100%'}
                height={convertSize?.height ?? height ?? 100}
            >
                <AreaChart
                    data={clusteredDataForSelectedDateRangeAndSymptom()}
                    ref={convertToPngRef}
                >
                    <defs>
                        <linearGradient id="colorMpn" x1="0" y1="0" x2="0" y2="1.1">
                            <stop offset="5%" stopColor="#E0C1C6" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#AB3042" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                        content={
                            <CustomToolTip
                                active={true}
                                payload={{}}
                                selectedSymptom={selectedSymptom}
                            />
                        }
                    />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke={theme['primary-500']}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorMpn)"
                        dot
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
);

export default React.memo(Chart);

const CustomToolTip = ({ active, payload, selectedSymptom }): JSX.Element => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="donut-container">
                    <div className="donut">
                        <DonutChart
                            data={[
                                { value: payload[0].payload.value, color: theme.white },
                                {
                                    value:
                                        (selectedSymptom ===
                                        SymptomDropdownExtendedMPNSymptomEnum.TotalScore
                                            ? 100
                                            : 10) - payload[0].payload.value,
                                    color: theme['primary-700'],
                                },
                            ]}
                            animate
                        />
                    </div>

                    <div className="donut-value">{payload[0].payload.value}</div>
                </div>
                <div className="date">{payload[0].payload.date}</div>
            </div>
        );
    }

    return <div></div>;
};
