import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Calendar: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 16 17" fill="none" {...props}>
                <g clipPath="url(#clip0)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.6 16.5H2.40005C1.51765 16.5 0.800049 15.7824 0.800049 14.9V3.7C0.800049 2.8176 1.51765 2.1 2.40005 2.1H3.20005C3.64188 2.1 4.00005 1.74183 4.00005 1.3C4.00005 0.858172 4.35822 0.5 4.80005 0.5C5.24188 0.5 5.60005 0.858172 5.60005 1.3C5.60005 1.74183 5.95822 2.1 6.40005 2.1H9.60005C10.0419 2.1 10.4 1.74183 10.4 1.3C10.4 0.858172 10.7582 0.5 11.2 0.5C11.6419 0.5 12 0.858172 12 1.3C12 1.74183 12.3582 2.1 12.8 2.1H13.6C14.4824 2.1 15.2 2.8176 15.2 3.7V14.9C15.2 15.7824 14.4824 16.5 13.6 16.5ZM12.6008 14.9C13.1531 14.9 13.6008 14.4522 13.6008 13.8999L13.6001 6.29992C13.6001 5.74766 13.1524 5.3 12.6001 5.3H3.40005C2.84776 5.3 2.40005 5.74771 2.40005 6.3V13.9C2.40005 14.4523 2.84776 14.9 3.40005 14.9H12.6008ZM5.60005 8.5C5.60005 8.05817 5.24188 7.7 4.80005 7.7C4.35822 7.7 4.00005 8.05817 4.00005 8.5C4.00005 8.94183 4.35822 9.3 4.80005 9.3C5.24188 9.3 5.60005 8.94183 5.60005 8.5ZM5.60005 11.7C5.60005 11.2582 5.24188 10.9 4.80005 10.9C4.35822 10.9 4.00005 11.2582 4.00005 11.7C4.00005 12.1418 4.35822 12.5 4.80005 12.5C5.24188 12.5 5.60005 12.1418 5.60005 11.7ZM8.80005 8.5C8.80005 8.05817 8.44188 7.7 8.00005 7.7C7.55822 7.7 7.20005 8.05817 7.20005 8.5C7.20005 8.94183 7.55822 9.3 8.00005 9.3C8.44188 9.3 8.80005 8.94183 8.80005 8.5ZM8.80005 11.7C8.80005 11.2582 8.44188 10.9 8.00005 10.9C7.55822 10.9 7.20005 11.2582 7.20005 11.7C7.20005 12.1418 7.55822 12.5 8.00005 12.5C8.44188 12.5 8.80005 12.1418 8.80005 11.7ZM12 8.5C12 8.05817 11.6419 7.7 11.2 7.7C10.7582 7.7 10.4 8.05817 10.4 8.5C10.4 8.94183 10.7582 9.3 11.2 9.3C11.6419 9.3 12 8.94183 12 8.5ZM12 11.7C12 11.2582 11.6419 10.9 11.2 10.9C10.7582 10.9 10.4 11.2582 10.4 11.7C10.4 12.1418 10.7582 12.5 11.2 12.5C11.6419 12.5 12 12.1418 12 11.7Z"
                        fill={fill}
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default Calendar;
