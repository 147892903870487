import React, { FunctionComponent, useState } from 'react';
import { Button, Row, Col, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { Padlock } from 'Components/icons';
import { theme } from 'Style/theme';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UserService } from 'Services/UserService';
import { ResetPasswordSchema } from 'Schemas/ResetPasswordSchema';
import './reset-password.less';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPassword: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [errors, validateForm] = useFormValidation(ResetPasswordSchema);
    const userService = useService(UserService);
    const { toastStore } = useStores();
    const [spinner, setSpinner] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    async function submit(): Promise<void> {
        const formValues = form.getFieldsValue();
        if (!(await validateForm(formValues))) return;

        const urlParams = new URLSearchParams(window.location.search);
        const requestIsPatient = urlParams.get('is-patient');

        const request: UpdateUserPasswordAnonymousRequestDto = {
            newPassword: formValues.newPassword,
            currentPassword: null,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
        };

        try {
            setSpinner(true);
            await userService.updateUserPasswordAnonymous(request);
            if (requestIsPatient === 'True') {
                history.push('/password-confirmed');
            } else {
                history.push('/console-password-confirmed');
            }
        } catch (error: any) {
            if (!error.treated) {
                toastStore.genericError();
            }
        } finally {
            setSpinner(false);
        }
    }

    return (
        <div className="ResetPassword">
            <Row className="heading">
                <Col className="H2-bold">{t('ForgotPassword.reset_password')}</Col>
            </Row>

            <Row>
                <Col span={16} offset={4}>
                    <Form layout="vertical" onFinish={submit} form={form}>
                        <ValidatedFormItem
                            errors={errors}
                            label={t('Login.password')}
                            name="newPassword"
                            className="password-input"
                        >
                            <Input
                                prefix={<Padlock fill={theme['primary-color']} />}
                                type="password"
                                maxLength={50}
                            />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            label={t('confirm_password')}
                            name="confirm"
                        >
                            <Input
                                prefix={<Padlock fill={theme['primary-color']} />}
                                type="password"
                                maxLength={50}
                            />
                        </ValidatedFormItem>

                        {spinner && (
                            <div className="spinner">
                                <Spin indicator={antIcon} size="large" />
                            </div>
                        )}

                        <Button type="primary" htmlType="submit" className="set-btn">
                            {t('set_password')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
});

export default ResetPassword;
