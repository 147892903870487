import React, { FunctionComponent } from 'react';
import { Select as AntdSelect } from 'antd';
import './select.less';
import { ChevronDown } from 'Components/icons';
import { theme } from 'Style/theme';

const { Option } = AntdSelect;

interface GenericSelectProps {
    onChange?: (selected: any) => void;
    options: {id: string; name: string}[];
    value?: any;
    defaultValue?: string;
    placeHolder?: string;
}

export interface SelectOption {
    id: string;
    name: string
}

const Select: FunctionComponent<GenericSelectProps> = ({
    onChange,
    options,
    value,
    defaultValue,
    placeHolder
}) => {
    return (
        <AntdSelect
            className="Select"
            defaultValue={defaultValue}
            onChange={onChange}
            style={{ width: '100%' }}
            value={value}
            suffixIcon={<ChevronDown fill={theme['primary-color']}/>}
            placeholder={placeHolder}
        >
            {options.map((filter) => (
                <Option key={filter.id} value={filter.id}>
                    {filter.name}
                </Option>
            ))}
        </AntdSelect>
    );
};

export default Select;
