import { action, observable } from 'mobx';

class AuthorizationStore {
    @observable currentRequestForbidden = false;
    @observable currentRequestNotFound = false;
    @observable userCannotAccessSite = false;

    @action
    handleErrorCode(statusCode: number): void {
        if (statusCode === 403) {
            this.currentRequestForbidden = true;
        }
        if (statusCode === 404) {
            this.currentRequestNotFound = true;
        }
    }

    @action
    resetNotFound(): void {
        this.currentRequestNotFound = false;
    }

    @action
    resetForbidden(): void {
        this.currentRequestForbidden = false;
    }

    @action 
    setCurrentRequestForbidden(): void {
        this.currentRequestForbidden = true;
    }

    @action
    handleUserCannotAccessSite(): void {
        this.userCannotAccessSite = true;
    }

    @action
    resetUserCannotAccessSite(): void {
        this.userCannotAccessSite = false;
    }
}
export default AuthorizationStore;
