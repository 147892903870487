import React, { FunctionComponent } from 'react';
import './patient-invitation-card.less';
import { CircleCheckmark, CircleX } from 'Components/icons';
import { theme } from 'Style/theme';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import { DiagnosisToString } from 'Models/Diagnosis/DiagnosisToString';
import { TreatmentTypeToString } from 'Models/TreatmentType/TreatmentTypeToString';
import { useTranslation } from 'react-i18next';

interface PatientInvitationCardProps {
    data?: PatientDto;
    onAcceptClick: (id?: string) => void;
    onDeclineClick: (id?: string) => void;
}

const PatientInvitationCard: FunctionComponent<PatientInvitationCardProps> = ({
    data,
    onAcceptClick,
    onDeclineClick,
}) => {
    const { t } = useTranslation();
    return (
        <div className="PatientInvitationCard Shadow-MPN">
            <div className="info-container">
                <div className="name">
                    {data?.firstName} {data?.lastName}
                </div>
                <div className="date">{data?.birthYear}</div>
                <div className="diagnosis">
                    {data?.diagnosis ? DiagnosisToString(data?.diagnosis, t) : null}
                </div>
                <div className="treatment">
                    {data?.treatmentType ? TreatmentTypeToString(data?.treatmentType, t) : null}
                </div>
            </div>

            <div className="action-container">
                <div className="button-container" onClick={() => onAcceptClick(data?.id)}>
                    <CircleCheckmark width={36} height={36} fill={theme['success-500']} />
                    <span className="text">{t('accept')}</span>
                </div>
                <div className="button-container" onClick={() => onDeclineClick(data?.id)}>
                    <CircleX width={36} height={36} fill={theme['grayscale-300']} />
                    <span className="text">{t('decline')}</span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(PatientInvitationCard);
