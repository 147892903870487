import { TreatmentTypeDto } from 'Api/Features/Patients/Dtos/TreatmentTypeDto';
import { TimeframeFilterEnum } from 'Filters/TimeframeFilter';
import { observable, action } from 'mobx';
import { ALL_ROLES, ALL_TREATMENTS } from 'Models/Constants';


class FilterStore {
    @observable treatmentType: TreatmentTypeDto | string = ALL_TREATMENTS;
    @observable timeframe: TimeframeFilterEnum = TimeframeFilterEnum.All;
    @observable role:string = ALL_ROLES;
    @observable searchTerm = '';

    @action
    updateTreatmentType(treatment: TreatmentTypeDto): void {
        this.treatmentType = treatment;
    }

    @action
    updateTimeframe(timeframe: TimeframeFilterEnum): void {
        this.timeframe = timeframe;
    }

    @action
    updateRole(role: string): void {
        this.role = role;
    }

    @action
    updateSearchTerm(term: string): void {
        this.searchTerm = term;
    }

    @action
    clear(): void {
        this.searchTerm = '';
    }
}

export default FilterStore;
