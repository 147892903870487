import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Stethoscope: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 32 33" fill="none" {...props}>
        <path
          d="M27.2 10.1C28.096 10.1 28.8 10.788 28.8 11.7C28.8 12.1243 28.6314 12.5313 28.3314 12.8314C28.0313 13.1314 27.6243 13.3 27.2 13.3C26.288 13.3 25.6 12.58 25.6 11.7C25.6 10.788 26.288 10.1 27.2 10.1ZM0 0.5V14.9C0 19.636 3.504 23.7 8.224 24.356C9.216 29.172 13.472 32.5 18.4 32.5C19.7657 32.5 21.1181 32.231 22.3799 31.7083C23.6417 31.1857 24.7882 30.4196 25.7539 29.4539C26.7196 28.4882 27.4857 27.3417 28.0083 26.0799C28.531 24.8181 28.8 23.4657 28.8 22.1V16.196C30.656 15.524 32 13.764 32 11.7C32 10.427 31.4943 9.20606 30.5941 8.30589C29.6939 7.40571 28.473 6.9 27.2 6.9C25.927 6.9 24.7061 7.40571 23.8059 8.30589C22.9057 9.20606 22.4 10.427 22.4 11.7C22.4 13.764 23.744 15.54 25.6 16.196V21.956C25.6 25.956 22.4 29.156 18.4 29.156C15.2 29.156 12.512 27.22 11.552 24.34C16 23.38 19.2 19.38 19.2 14.9V0.5H12.8V5.3H16V14.9C16 16.5974 15.3257 18.2253 14.1255 19.4255C12.9253 20.6257 11.2974 21.3 9.6 21.3C7.90261 21.3 6.27475 20.6257 5.07452 19.4255C3.87428 18.2253 3.2 16.5974 3.2 14.9V5.3H6.4V0.5H0Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Stethoscope;
