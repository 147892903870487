import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { PatientProxy } from 'Api/Features/Patients/PatientProxy';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import { TimeframeFilterEnum, TimeFrameFilterToTimeStrings } from 'Filters/TimeframeFilter';
import { CreatePatientRequestDto } from 'Api/Features/Patients/Dtos/CreatePatientRequestDto';
import { UpdatePatientRequestDto } from 'Api/Features/Patients/Dtos/UpdatePatientRequestDto';
import { GetPhysicanPatientsRequestDto } from 'Api/Features/Patients/Dtos/GetPhysicanPatientsRequestDto';
import { GetPhysicanPatientsResponseDto } from 'Api/Features/Patients/Dtos/GetPhysicanPatientsResponseDto';
import { ExportPatientStatisticsReportRequestDto } from 'Api/Features/Patients/Dtos/ExportPatientStatisticsReportRequestDto';
import { arrayBufferToPdf } from 'Utils/Blob';

export interface GetPatientsRequest extends GetPhysicanPatientsRequestDto {
    timeframe?: TimeframeFilterEnum;
}

@inject(PatientProxy)
export class PatientService extends ApiService {
    constructor(private readonly patientProxy: PatientProxy) {
        super();
    }

    public async getPatient(patientId: string): Promise<PatientDto | null> {
        const data: PatientDto | null = await this.patientProxy.getPatient(patientId);
        return data;
    }

    public async getPhysicanPatients(id: string, request: GetPatientsRequest | null): Promise<[PatientDto[], number]> {
        if (request?.timeframe && request.timeframe !== TimeframeFilterEnum.All) {
            const timeframe = TimeFrameFilterToTimeStrings(TimeframeFilterEnum[request.timeframe]);
            request.minLastSubmissionDate = timeframe.start;
            request.maxLastSubmissionDate = timeframe.end;
            request.timeframe = undefined;
        } else if (request?.timeframe) {
            request.timeframe = undefined;
        }

        const data: GetPhysicanPatientsResponseDto | null = await this.patientProxy.getPhysicanPatients(id, request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createPatient(request: CreatePatientRequestDto | null): Promise<string> {
        const data: string = await this.patientProxy.createPatient(request);
        return data;
    }

    public async updatePatient(
        patientId: string,
        request: UpdatePatientRequestDto | null
    ): Promise<void> {
        await this.patientProxy.updatePatient(patientId, request);
    }

    public async deletePatient(patientId: string): Promise<void> {
        await this.patientProxy.deletePatient(patientId);
    }

    public async exportPatientStatisticsReport(patientId: string, request: ExportPatientStatisticsReportRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.patientProxy.exportPatientStatisticsReport(patientId, request);
        return arrayBufferToPdf(data);
    }
}
