import { inject } from 'aurelia-dependency-injection';
import { HttpError } from 'HttpClient/HttpClient';
import { ToastStore } from 'Stores';

@inject(ToastStore)
export class HttpErrorCodeHandler {
    constructor(private readonly toastStore: ToastStore) {}

    public handleErrorCodes(httpError: HttpError): boolean {
        if(!httpError.response?.data?.errorCode) {
            return false;
        }
        const error = httpError.response.data.errorCode;
        let treated = false;

        if(error === "E015003") {
            this.toastStore.toast({
                type: 'error',
                message: 'Unsupported file extension'
            })
            treated = true;
        }
        
        return treated;
    }

    public HandleBadRequest(httpError: HttpError): boolean {
        let treated = false;
        if(httpError.response?.data?.status === 400 && httpError.response.data?.errors) {
            if(httpError.response.data.errors.email) {
                this.toastStore.toast({
                    type: 'error',
                    message: httpError.response.data.errors.email[0].description
                })
                treated = true;
            }
        }
        else 
            return treated;
        
        
        return treated;
    }
}