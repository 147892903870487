import React, { FunctionComponent, useEffect } from 'react';
import { ChevronDown, ChevronUp, QuestionMarkCircle } from 'Components/icons';
import { observer } from 'mobx-react';
import { theme } from 'Style/theme';
import { Collapse, Divider } from 'antd';
import './patient-other-questionnaire-history-collapse.less';
import { ReactNode } from 'react';
import { useState } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import moment from 'moment';
import { useStores } from 'Hooks';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import { MOMENT_DAY_MONTH_YEAR_STRING_FORMAT } from 'Models/Constants';
import { QuestionResponseDto } from 'Api/Features/SurveyResponses/Dtos/QuestionResponseDto';
import { QuestionDto } from 'Api/Features/Surveys/Dtos/QuestionDto';
import Tag, { TagColor, TagSize } from 'Components/tag/tag';
import { useTranslation } from 'react-i18next';
import { getLangQuestionDtoField } from 'Utils/SurveyResponseDtoUtils';

interface PatientOtherQuestionnaireHistoryCollapseProps {
    index: number;
    data: SurveyResponseDto;
    onSymptomTooltipClick: (question?: QuestionDto) => void;
    isExpanded: boolean;
    onExpandClick: (index: number, isExpanded: boolean) => void;
}

const { Panel } = Collapse;

const PatientOtherQuestionnaireHistoryCollapse: FunctionComponent<PatientOtherQuestionnaireHistoryCollapseProps> =
    observer(({ data, onSymptomTooltipClick, isExpanded, onExpandClick, index }) => {
        const { t } = useTranslation();
        const { surveyStore, languageStore } = useStores();
        const [expanded, setExpanded] = useState(isExpanded);
        const [survey, setSurvey] = useState<SurveyDto>();

        useEffect(() => {
            const surveyInfo = surveyStore.surveys.find((survey) => survey.id === data.surveyId);
            if (surveyInfo) setSurvey(surveyInfo);
        }, [data, surveyStore.surveys]);

        const getHeader = (): ReactNode => (
            <div className="header">
                <div className="date">
                    {moment(data.date).utc().format(MOMENT_DAY_MONTH_YEAR_STRING_FORMAT)}
                </div>
                <div className="title">
                    {survey?.[`title${getLangQuestionDtoField(languageStore.currentLanguage)}`]}
                </div>
            </div>
        );

        const singleValueContent = (
            questionResponses: QuestionResponseDto,
            surveyStoreQuestion: QuestionDto | undefined
        ): ReactNode => {
            return (
                <div className="question-container">
                    <div className="question single-answer-question">
                        <div className="name">
                            {
                                surveyStoreQuestion?.[
                                    `title${getLangQuestionDtoField(languageStore.currentLanguage)}`
                                ]
                            }
                        </div>
                        <div
                            className="tooltip"
                            onClick={() => onSymptomTooltipClick(surveyStoreQuestion)}
                        >
                            {<QuestionMarkCircle fill={theme['primary-color']} />}
                        </div>
                        <div className="score">{questionResponses.numericValue}</div>
                    </div>
                </div>
            );
        };

        const multiValueContent = (
            questionResponses: QuestionResponseDto,
            surveyStoreQuestion: QuestionDto | undefined
        ): ReactNode => {
            return (
                <div className="question-container">
                    <div className="question multiple-answer-question">
                        <div className="name">
                            {
                                surveyStoreQuestion?.[
                                    `title${getLangQuestionDtoField(languageStore.currentLanguage)}`
                                ]
                            }
                        </div>
                        <div
                            className="tooltip"
                            onClick={() => onSymptomTooltipClick(surveyStoreQuestion)}
                        >
                            {<QuestionMarkCircle fill={theme['primary-color']} />}
                        </div>
                    </div>

                    <div className="multiple-answer-container">
                        <div className="divider">
                            <Divider />
                        </div>

                        <div className="answers">
                            {questionResponses.choiceValues?.map((choiceValue) => {
                                return (
                                    <Tag
                                        key={questionResponses.id + choiceValue}
                                        text={
                                            surveyStoreQuestion?.choices?.find(
                                                (choice) => choice?.id === choiceValue
                                            )?.[
                                                `description${getLangQuestionDtoField(
                                                    languageStore.currentLanguage
                                                )}`
                                            ] ?? ''
                                        }
                                        color={TagColor.Grey}
                                        size={TagSize.Small}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        };

        const getContent = (questionResponses: QuestionResponseDto): ReactNode => {
            const surveyStoreQuestion: QuestionDto | undefined =
                survey?.questions?.find(
                    (question) => question?.id === questionResponses.questionId
                ) ?? undefined;
            const isMultiValueQuestion =
                questionResponses.choiceValues && questionResponses.choiceValues?.length > 0;

            return isMultiValueQuestion
                ? multiValueContent(questionResponses, surveyStoreQuestion)
                : singleValueContent(questionResponses, surveyStoreQuestion);
        };

        return (
            <div className="PatientOtherQuestionnaireHistoryCollapse">
                <Collapse
                    expandIconPosition={'right'}
                    expandIcon={(props) =>
                        props.isActive ? (
                            <ChevronUp width={22} height={22} fill={theme['primary-color']} />
                        ) : (
                            <ChevronDown width={22} height={22} fill={theme['primary-color']} />
                        )
                    }
                    onChange={() => {
                        onExpandClick(index, !expanded);
                        setExpanded((oldState) => !oldState);
                    }}
                    activeKey={expanded ? 1 : undefined}
                >
                    <Panel
                        key={1}
                        header={getHeader()}
                        extra={expanded ? <span className="close">{t('close')}</span> : undefined}
                    >
                        <div className="open-panel-content">
                            <div className="questionnaire-container">
                                {data.questionResponses
                                    ?.filter((questionResponses) => questionResponses !== null)
                                    .map((questionResponses) => questionResponses!)
                                    .map((questionResponses: QuestionResponseDto) =>
                                        getContent(questionResponses)
                                    )}
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        );
    });

export default React.memo(PatientOtherQuestionnaireHistoryCollapse);
