import { observable, action } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { AuthenticationStore } from 'Stores';
import { UserService } from 'Services/UserService';
import { ManagerUserDetailsDto } from 'Api/Features/Users/Dtos/ManagerUserDetailsDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { PhysicianService } from 'Services/PhysicianService';

export interface ManagerUserDetailsExtended extends ManagerUserDetailsDto {
    phoneNumber?: string;
    imageUrl?: string | null;
}

@inject(UserService, AuthenticationStore, PhysicianService)
class UserStore {
    constructor(
        private readonly userService: UserService,
        private readonly authenticationStore: AuthenticationStore,
        private readonly physicianService: PhysicianService
    ) {}

    @observable 
    public callInProgress = false;

    @observable
    public userInfo: ManagerUserDetailsExtended | null = null;

    @observable 
    public userRole: ManagementRoleDto | undefined = undefined;

    @observable
    public userIsAdminOrSuperAdmin = false;

    @action
    async setUserInfo(): Promise<void> {
        this.callInProgress = true;
        const userId = this.authenticationStore.userId;
        const userInfo = await this.userService.getManagerUser(userId!);
        this.userInfo = userInfo;
        this.userRole = userInfo?.managementRoles?.[0]?.name;
        this.userIsAdminOrSuperAdmin =
            userInfo?.managementRoles?.[0]?.name === ManagementRoleDto.Administrator ||
            userInfo?.managementRoles?.[0]?.name === ManagementRoleDto.SuperAdministrator;

        if(userInfo && userInfo.managementRoles?.some(role => role?.name === ManagementRoleDto.Physician)) {
            const response = await this.physicianService.getPhysician(userId!);
            this.userInfo = { ...this.userInfo, phoneNumber: response?.phoneNumber ?? undefined, imageUrl: response?.imageUrl };
        }
        this.callInProgress = false;
    }

    @action 
    setCallInProgress(value: boolean): void {
        this.callInProgress = value;
    }

    @action
    clearUserInfo(): void {
        this.userInfo = null;
        this.userRole = undefined;
    }
}

export default UserStore;
