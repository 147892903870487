import { Button, Typography } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import { Close } from 'Components/icons';
import React, { FunctionComponent, ReactNode } from 'react';
import { theme } from 'Style/theme';
import './confirmation-modal.less';

const { Title } = Typography;

interface ModalAction {
    text: string;
    action: () => void;
}

export interface ConfirmationModalProps {
    id: string;
    icon: ReactNode;
    title: string;
    message?: string | ReactNode;
    positive: ModalAction;
    negative?: ModalAction;
    width?: number | null;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    icon,
    title,
    message,
    positive,
    negative,
    width,
}) => {
    return (
      <BaseModal
        visible
        className="ConfirmationModal"
        width={width ? width : 780}
        onCancel={negative && negative.action}
        closeIcon={<Close fill={theme.black} />}
      >
        {icon && <div className="icon">{icon}</div>}
        <Title level={2} className="title">
          {title}
        </Title>
        {message && <div className="message">{message}</div>}
        <div className="actions">
          {negative && negative.text && (
            <Button
              type="default"
              className="negative secondary"
              onClick={negative.action}
            >
              {negative.text}
            </Button>
          )}
          <Button type="primary" className="positive" onClick={positive.action}>
            {positive.text}
          </Button>
        </div>
      </BaseModal>
    );
};

export default ConfirmationModal;
