import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';

const UnauthorizedPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="UnauthorizedPage">
            <div className="e403">{'403'}</div>
            <div className="H1-bold not-found">{t('Page403.page_403_title')}</div>
            <div className="Paragraph text">{t('Page403.page_403')}</div>
            <Button type="primary" className="back-to-site">
                <Link to="/">{t('go_back')}</Link>
            </Button>
        </div>
    );
};

export default UnauthorizedPage;
