import React, { FunctionComponent } from 'react';
import Chart from 'Components/chart/chart';
import { observer } from 'mobx-react';
import DonutChart from 'Components/donut-chart/donut-chart';
import { theme } from 'Style/theme';
import './patient-statistics.less';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { useCallback } from 'react';
import {
    Mpn10StringQuestionMap,
    SymptomDropdownExtendedMPNSymptomEnum,
} from 'Models/Mpn10Question/Mpn10QuestionMap';
import { DateRangeDropdownOption } from 'Components/date-range-dropdown';
import { useTranslation } from 'react-i18next';

interface PatientStatisticsProps {
    data: SurveyResponseDto[];
    selectedSymptom: SymptomDropdownExtendedMPNSymptomEnum;
    selectedDateRange: DateRangeDropdownOption;
}

const PatientStatistics: FunctionComponent<PatientStatisticsProps> = observer(
    ({ data, selectedSymptom, selectedDateRange }) => {
        const { t } = useTranslation();
        const averageScore = useCallback(
            (
                data: SurveyResponseDto[],
                selectedSymptom: SymptomDropdownExtendedMPNSymptomEnum
            ): number => {
                if (data.length > 0) {
                    if (selectedSymptom === SymptomDropdownExtendedMPNSymptomEnum.TotalScore) {
                        const filtered = data
                            .filter((survey) => survey.score !== undefined)
                            .map((survey) => ({ ...survey, score: survey.score! }));

                        return Math.round(
                            parseFloat(
                                (
                                    filtered
                                        .map((survey) => survey.score)
                                        .reduce((a, b) => a! + b!) / filtered.length
                                ).toFixed(2)
                            )
                        );
                    } else {
                        const selectedSymptomId: string = Mpn10StringQuestionMap[selectedSymptom];
                        const specificScores = data
                            .filter((survey) => survey !== undefined)
                            .map((survey) => survey!)
                            .flatMap((survey) =>
                                survey.questionResponses
                                    ?.filter(
                                        (question) =>
                                            question &&
                                            question.questionId === selectedSymptomId &&
                                            question.numericValue !== undefined
                                    )
                                    .map((question) => question!.numericValue!)
                            )
                            .filter((score) => score !== undefined)
                            .map((score) => score!);

                        if (specificScores) {
                            return Math.round(
                                parseFloat(
                                    (
                                        specificScores.reduce((a, b) => a! + b!) /
                                        specificScores.length
                                    ).toFixed(2)
                                )
                            );
                        } else return 0;
                    }
                } else return 0;
            },
            [data, selectedSymptom]
        );

        return (
            <div className="PatientStatistics">
                <div className="card-container">
                    <div className="average-score-container">
                        <div className="donut-container">
                            <DonutChart
                                data={[
                                    {
                                        value: averageScore(data, selectedSymptom),
                                        color: theme['primary-500'],
                                    },
                                    {
                                        value:
                                            (selectedSymptom ===
                                            SymptomDropdownExtendedMPNSymptomEnum.TotalScore
                                                ? 100
                                                : 10) - averageScore(data, selectedSymptom),
                                        color: theme['grayscale-200'],
                                    },
                                ]}
                                animate
                            />
                        </div>
                        <div className="score-container">
                            <div className="H4-bold score">
                                {averageScore(data, selectedSymptom)}
                            </div>
                            <div className="Paragraph">{t('average_score')}</div>
                        </div>
                    </div>

                    <div className="chart-container">
                        <Chart
                            height={421}
                            data={data}
                            selectedSymptom={selectedSymptom}
                            selectedDateRange={selectedDateRange}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default React.memo(PatientStatistics);
