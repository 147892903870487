import * as yup from 'yup';
import { StringSchema } from 'yup';

export const ResetPasswordEmailSchema = yup.object({
    email: yup.string().email('Invalid e-mail address').required('Required'),
});

export const ResetPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .required("Required")
    .min(6, "Password must be between 6 and 50 caracters")
    .max(50, "Password must be between 6 and 50 caracters"),
  confirm: yup
    .string()
    .required("Required")
    .when(
      "newPassword",
      (newPassword: string, schema: StringSchema) =>
      newPassword && schema.equals([newPassword], "Passwords don't match")
    ),
});

export const SetPasswordSchema = yup.object({
    newPassword: yup
        .string()
        .required('Required')
        .min(6, 'Password must be between 6 and 50 caracters')
        .max(50, 'Password must be between 6 and 50 caracters'),
    confirm: yup
        .string()
        .required('Required')
        .when(
            'newPassword',
            (newPassword: string, schema: StringSchema) =>
                newPassword && schema.equals([newPassword], "Passwords don't match")
        ),
    isPatient: yup.bool(),
    acceptedTerms: yup.bool().when('isPatient', { is: true, then: yup.bool().oneOf([true], 'Required')}),
});