import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const LogOut: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 18 22" fill="none" {...props}>
        <path
          d="M13 16.5V13.2H6V8.8H13V5.5L18 11L13 16.5ZM11 0C11.5304 0 12.0391 0.231785 12.4142 0.644365C12.7893 1.05694 13 1.61652 13 2.2V4.4H11V2.2H2V19.8H11V17.6H13V19.8C13 20.3835 12.7893 20.9431 12.4142 21.3556C12.0391 21.7682 11.5304 22 11 22H2C1.46957 22 0.960859 21.7682 0.585786 21.3556C0.210714 20.9431 0 20.3835 0 19.8V2.2C0 1.61652 0.210714 1.05694 0.585786 0.644365C0.960859 0.231785 1.46957 0 2 0H11Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default LogOut;
