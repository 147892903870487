import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Search: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="16" height="20" viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M12.8034 11.3079C12.6866 11.1895 12.6706 10.9851 12.7602 10.844C13.4642 9.74597 13.877 8.43872 13.877 7.03416C13.877 3.15459 10.7633 0 6.93929 0C3.11204 0 0 3.15459 0 7.03416C0 10.9121 3.11204 14.0667 6.93929 14.0667C8.42891 14.0667 9.80812 13.5818 10.9409 12.7676C11.0737 12.6702 11.2737 12.6832 11.3905 12.8L13.8706 15.3139C13.8866 15.3302 13.9042 15.3464 13.9218 15.3594C13.9506 15.3837 14.0706 15.4972 14.1858 15.6156L14.4802 15.9124C14.597 16.0292 14.7858 16.0292 14.901 15.9124L15.9122 14.8874C16.029 14.769 16.029 14.5776 15.9138 14.4592L12.8034 11.3079ZM11.1361 10.3104C10.9073 10.6121 10.6497 10.8878 10.3649 11.1327C9.44012 11.929 8.2449 12.4124 6.93929 12.4124C4.01285 12.4124 1.63202 9.99899 1.63202 7.03416C1.63202 4.06934 4.01285 1.65433 6.93929 1.65433C9.86412 1.65433 12.245 4.06771 12.245 7.03416C12.245 8.2668 11.8273 9.40213 11.1361 10.3104Z"
          fill={fill}
        />
      </svg>
    </span>
  );
};

export default Search;
