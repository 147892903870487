import { Col, Row } from 'antd';
import { List } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './index.less';

const NoQuestionnaireData: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="NoQuestionnaireData">
            <Row>
                <Col span={24}>
                    <div className="no-data-container">
                        <List fill={theme['grayscale-500']} width={45} height={45} />
                        <div className="title">{t('no_data_yet')}</div>
                        <div className="text">{t('NoQuestionnaireData.no_completed_question')}</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default NoQuestionnaireData;
