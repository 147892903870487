import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import {
    AuthenticationStore,
    AuthorizationStore,
    ToastStore,
    ConfirmationModalStore,
    UserStore,
    LoadingStore,
    LanguageStore,
} from 'Stores';
import SurveyStore from 'Stores/SurveyStore';

export interface GlobalStores {
    authenticationStore: AuthenticationStore;
    authorizationStore: AuthorizationStore;
    toastStore: ToastStore;
    confirmationModalStore: ConfirmationModalStore;
    userStore: UserStore;
    globalLoadingStore: LoadingStore;
    surveyStore: SurveyStore;
    languageStore: LanguageStore;
}

@inject(
    AuthenticationStore,
    AuthorizationStore,
    ToastStore,
    ConfirmationModalStore,
    UserStore,
    LoadingStore,
    SurveyStore,
    LanguageStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        authenticationStore: AuthenticationStore,
        authorizationStore: AuthorizationStore,
        toastStore: ToastStore,
        confirmationModalStore: ConfirmationModalStore,
        userStore: UserStore,
        globalLoadingStore: LoadingStore,
        surveyStore: SurveyStore,
        languageStore: LanguageStore
    ) {
        this.context = React.createContext({
            authenticationStore,
            authorizationStore,
            toastStore,
            confirmationModalStore,
            userStore,
            globalLoadingStore,
            surveyStore,
            languageStore,
        });
    }
}
