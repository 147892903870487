import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CirclePlusSign: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 32 33" fill="none" {...props}>
                <path
                    d="M0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 4.5C16.7364 4.5 17.3333 5.09695 17.3333 5.83333V15.1667H26.6667C27.403 15.1667 28 15.7636 28 16.5C28 17.2364 27.403 17.8333 26.6667 17.8333H17.3333V27.1667C17.3333 27.903 16.7364 28.5 16 28.5C15.2636 28.5 14.6667 27.903 14.6667 27.1667V17.8333H5.33333C4.59695 17.8333 4 17.2364 4 16.5C4 15.7636 4.59695 15.1667 5.33333 15.1667H14.6667V5.83333C14.6667 5.09695 15.2636 4.5 16 4.5Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default CirclePlusSign;
