import React, { FunctionComponent } from 'react';
import moment from 'moment';
import './last-mpn-answer-badge.less';
import { MOMENT_DAY_MONTH_YEAR_STRING_FORMAT } from 'Models/Constants';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';

interface LastMPNAnswerBadgeProps {
    lastAnswerDate?: string;
    displayDate?: boolean;
}

const LastMPNAnswerBadge: FunctionComponent<LastMPNAnswerBadgeProps> = observer(
    ({ lastAnswerDate, displayDate }) => {
        const badgeDetails = (): {
            value: string;
            className: string;
        } => {
            const { t } = useTranslation();
            const { languageStore } = useStores();
            const currentMoment = moment();
            const lastAnswerMoment = moment(lastAnswerDate);
            const timeElapsedInDays = currentMoment.diff(lastAnswerMoment, 'days');
            const timeElapsedInWeeks = currentMoment.diff(lastAnswerMoment, 'weeks');
            const timeElapsedInMonths = currentMoment.diff(lastAnswerMoment, 'months');
            const timeElapsedInYears = currentMoment.diff(lastAnswerMoment, 'years');

            let result = { value: '', className: '' };

            if (lastAnswerDate === undefined) {
                result = {
                    value: t('no_data_yet'),
                    className: 'grey',
                };
            } else if (timeElapsedInYears > 0) {
                result = {
                    value: t('MPNAnswerBadge.mpn_answer_badge', {
                        param1: timeElapsedInYears,
                        param2: t('MPNAnswerBadge.MPNAnswerBadge_year'),
                        param3:
                            timeElapsedInYears > 1
                                ? t('MPNAnswerBadge.mpn_answer_badge_pluralise')
                                : '',
                    }),
                    // `${timeElapsedInYears} year${timeElapsedInDays > 1 ? 's' : ''} ago`,
                    className: 'red',
                };
            } else if (timeElapsedInMonths > 0) {
                result = {
                    value: t('MPNAnswerBadge.mpn_answer_badge', {
                        param1: timeElapsedInMonths,
                        param2: t('MPNAnswerBadge.MPNAnswerBadge_month'),
                        param3:
                            timeElapsedInMonths > 1
                                ? languageStore.currentLanguage === 'fr'
                                    ? ''
                                    : t('MPNAnswerBadge.mpn_answer_badge_pluralise')
                                : '',
                    }),
                    // `${timeElapsedInMonths} month${timeElapsedInMonths > 1 ? 's' : ''} ago`,
                    className: timeElapsedInMonths > 2 ? 'orange' : 'yellow',
                };
            } else if (timeElapsedInWeeks > 0) {
                result = {
                    value: t('MPNAnswerBadge.mpn_answer_badge', {
                        param1: timeElapsedInWeeks,
                        param2: t('MPNAnswerBadge.MPNAnswerBadge_week'),
                        param3:
                            timeElapsedInWeeks > 1
                                ? t('MPNAnswerBadge.mpn_answer_badge_pluralise')
                                : '',
                    }),
                    // `${timeElapsedInWeeks} week${timeElapsedInWeeks > 1 ? 's' : ''} ago`,
                    className: 'green',
                };
            } else {
                result = {
                    value: t('MPNAnswerBadge.mpn_answer_badge', {
                        param1: timeElapsedInDays,
                        param2: t('MPNAnswerBadge.MPNAnswerBadge_day'),
                        param3:
                            timeElapsedInDays > 1
                                ? t('MPNAnswerBadge.mpn_answer_badge_pluralise')
                                : '',
                    }),
                    // `${timeElapsedInDays} day${timeElapsedInDays > 1 ? 's' : ''} ago`,
                    className: 'green',
                };
            }

            if (displayDate && lastAnswerDate !== undefined) {
                result = {
                    ...result,
                    value: `${t('MPNAnswerBadge.MPNAnswerBadge_last_answer_on')} ${moment(
                        lastAnswerDate
                    ).format(MOMENT_DAY_MONTH_YEAR_STRING_FORMAT)}`,
                };
            }

            return result;
        };

        return (
            <div className="LastMPNAnswerBadge">
                <div className={`inner-container ${badgeDetails().className}`}>
                    <div className="circle" />
                    <div className="text">{`${badgeDetails().value} `}</div>
                </div>
            </div>
        );
    }
);

export default LastMPNAnswerBadge;
